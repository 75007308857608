// App.jsx
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Home from './Components/Home';
import AboutUs from './Components/AboutUs/AboutUs';
import Main from './Components/Channeling/Main';
import Signup from './Components/Channeling/Signup';
import Checkout from './Components/Channeling/Checkout';
import Login from './Components/Admin/Login';
import RegistrationForm from './Components/Admin/RegistrationForm';
import AddDoctor from './Components/Admin/AddDoctor';
import AllDoctor from './Components/Admin/AllDoctor';
import AllDoctorUser from './Components/User/AllDoctorUser';
import AllSesioonuser from './Components/User/AllSesioon_user';
import CreateSessionForm from './Components/Admin/CreateSessionForm';
import AllSessionAdmin from './Components/Admin/AllSessionAdmin';
import CheckOutForm from './Components/User/CheckOutForm';
import AppointmentRemove from './Components/Admin/AppointmentRemove';
import AdminDashboard from './Components/Admin/AdminDashboard';
import EditDoctor from './Components/Admin/EditDoctor';
import Invoice from './Components/User/Invoice';
import AddBlog from './Components/Admin/AddBlog';
import AllSumerizeblog from './Components/User/AllSumerizeblog';
import SingleBlogPage from './Components/User/SingleBlogPage';
import AllBlogAdmin from './Components/Admin/AllBlogAdmin';
import EditBlog from './Components/Admin/EdditBlog';
import ResidentialTreatment from "./Components/Services/Residential";
import Psychiatric from "./Components/Services/Psychiatric";
import Counseling from "./Components/Services/Counceling";
import Behavioral from "./Components/Services/Behavioral";
import Training from "./Components/Services/Training";
import Certificate from "./Components/Education/CertificateCourses/Certficate";
import HigherDiploma from "./Components/Education/CertificateCourses/HigherDiploma";
import Diploma from "./Components/Education/CertificateCourses/Diploma";
import Internships from "./Components/Education/CertificateCourses/Internships";
import ProtectedRoute from './Context/ProtectedRoutes';
import Gallery from './Components/Gallery/gallery';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <div className="pages">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/main" element={<Main />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<RegistrationForm />} />
            <Route path="/Residential" element={<ResidentialTreatment />} />
            <Route path="/Psychiatric" element={<Psychiatric />} />
            <Route path="/Counceling" element={<Counseling />} />
            <Route path="/Behavioral" element={<Behavioral />} />
            <Route path="/Training" element={<Training />} />
            <Route path="/Certificate" element={<Certificate />} />
            <Route path="/HigherDiploma" element={<HigherDiploma />} />
            <Route path="/Diploma" element={<Diploma />} />
            <Route path="/Internships" element={<Internships />} />
            <Route path="/add-doctor" element={<ProtectedRoute element={<AddDoctor />} />} />
            <Route path="/all-doctor" element={<ProtectedRoute element={<AllDoctor />} />} />
            <Route path="/all-doc-user" element={<ProtectedRoute element={<AllDoctorUser />} />} />
            <Route path="/AllSession-Admin" element={<ProtectedRoute element={<AllSessionAdmin />} />} />
            <Route path="/CreateSessionForm" element={<ProtectedRoute element={<CreateSessionForm />} />} />
            <Route path="/AllSesioon_user" element={<ProtectedRoute element={<AllSesioonuser />} />} />
            <Route path="/CheckOutForm/:sessionId" element={<ProtectedRoute element={<CheckOutForm />} />} />
            <Route path="/AppointmentRemove" element={<ProtectedRoute element={<AppointmentRemove />} />} />
            <Route path="/AdminDashboard" element={<ProtectedRoute element={<AdminDashboard />} />} />
            <Route path="/editdoctor/:doctorId" element={<ProtectedRoute element={<EditDoctor />} />} />
            <Route path="/invoice/:sessionId" element={<ProtectedRoute element={<Invoice />} />} />
            <Route path="/AddBlog" element={<ProtectedRoute element={<AddBlog />} />} />
            <Route path="/AllSumerizeblog" element={<ProtectedRoute element={<AllSumerizeblog />} />} />
            <Route path="/blog/:blogId" element={<SingleBlogPage />} />
            <Route path="/AllBlogAdmin" element={<ProtectedRoute element={<AllBlogAdmin />} />} />
            <Route path="/EditBlog/:blogId" element={<ProtectedRoute element={<EditBlog />} />} />
            <Route path="/Gallery" element={<Gallery />} />
          </Routes>
        </div>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
