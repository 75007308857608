import React from 'react';
import './Residential.css';

const Psychiatric = () => {
  return (
    <div className="residential-treatment-container">
      <div className="main-title">Psychiatrist Treatment Facility</div>
      <div className="paragraph">
        At The Helping Hand Psychological Hospital, we are committed to providing compassionate and effective psychiatric care for individuals struggling with mental health challenges. Our Psychiatrist Treatment Facility offers comprehensive services tailored to each patient's unique needs.
      </div>

      <section>
        <div className="section-title">Our Team of Experts</div>
        <div className="paragraph">Our team of highly skilled and experienced psychiatrists specialize in a wide range of mental health conditions, including:</div>
        <ul>
          <li>Anxiety disorders</li>
          <li>Mood disorders</li>
          <li>Psychotic disorders</li>
          <li>Substance use disorders</li>
          <li>Personality disorders</li>
        </ul>
      </section>

      <section>
        <div className="section-title">Treatment Services</div>
        <div className="paragraph">We offer a full spectrum of treatment services, including:</div>
        <ul>
          <li>Individual and group therapy</li>
          <li>Medication management</li>
          <li>Electroconvulsive therapy (ECT)</li>
          <li>Transcranial magnetic stimulation (TMS)</li>
          <li>Dialectical behavior therapy (DBT)</li>
          <li>Cognitive-behavioral therapy (CBT)</li>
        </ul>
      </section>

      <section>
        <div className="section-title">Our Commitment to Patient Care</div>
        <div className="paragraph">We believe that every patient deserves access to quality mental health care. We are committed to:</div>
        <ul>
          <li>Providing a safe and supportive environment</li>
          <li>Respecting patient confidentiality</li>
          <li>Collaborating with patients and their families</li>
          <li>Utilizing evidence-based treatments</li>
        </ul>
      </section>

      <section>
        <div className="section-title">Contact Us</div>
        <div className="paragraph">If you are seeking psychiatric care, we encourage you to contact us. Our team is here to help you take the first step towards a healthier future.</div>
      </section>
    </div>
  );
};

export default Psychiatric;
