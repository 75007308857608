import React, { useEffect, useState } from "react";
import axios from "axios";
import "./AppointmentRemove.css"; // Import your CSS file
import { FaTrash } from "react-icons/fa"; // Import the trash icon
import Swal from "sweetalert2"; // Import SweetAlert

const AppointmentRemove = () => {
  const [appointments, setAppointments] = useState([]);
  const [removing, setRemoving] = useState(false); // New state for removal in progress
  const [filterDoctorName, setFilterDoctorName] = useState(null);
  const [filterLocation, setFilterLocation] = useState(null);
  const [filterFullName, setFilterFullName] = useState("");

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://api.helpinghandlk.com/api/admin/get-all-appoinments",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          setAppointments(response.data.data);
        } else {
          console.log("Error fetching appointments:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching appointments:", error);
      }
    };

    fetchAppointments();
  }, []);

  const handleRemoveAppointment = async (appointment) => {
    try {
      setRemoving(true); // Set removing to true when removal begins
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `https://api.helpinghandlk.com/api/admin/SessionSlot-clear/${appointment.id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        Swal.fire({
          icon: "success",
          title: "Appointment Removed",
          text: response.data.message,
        });
        setAppointments((prevAppointments) =>
          prevAppointments.filter((a) => a.id !== appointment.id)
        );
      } else {
        console.log("Error removing appointment:", response.data.message);
      }
    } catch (error) {
      console.error("Error removing appointment:", error);
    } finally {
      setRemoving(false); // Reset removing to false regardless of success or failure
    }
  };

  const filteredAppointments = appointments.filter((appointment) => {
    return (
      (!filterDoctorName || appointment.DoctorName === filterDoctorName) &&
      (!filterLocation || appointment.location === filterLocation) &&
      appointment.full_name.toLowerCase().includes(filterFullName.toLowerCase())
    );
  });

  return (
    <div className="container">
      <div className="filter">
        <label>Filter by Doctor Name:</label>
        <select
          onChange={(e) => setFilterDoctorName(e.target.value)}
          value={filterDoctorName || ""}
        >
          <option value="">All</option>
          {Array.from(
            new Set(appointments.map((appointment) => appointment.DoctorName))
          ).map((doctorName, index) => (
            <option key={index} value={doctorName}>
              {doctorName}
            </option>
          ))}
        </select>
      </div>
      <div className="filter">
        <label>Filter by Location:</label>
        <select
          onChange={(e) => setFilterLocation(e.target.value)}
          value={filterLocation || ""}
        >
          <option value="">All</option>
          {Array.from(
            new Set(appointments.map((appointment) => appointment.location))
          ).map((location, index) => (
            <option key={index} value={location}>
              {location}
            </option>
          ))}
        </select>
      </div>
      <div className="filter">
        <label>Search by Full Name:</label>
        <input
          type="text"
          value={filterFullName}
          onChange={(e) => setFilterFullName(e.target.value)}
          placeholder="Enter full name"
        />
      </div>

      <table className="appointments-table">
        <thead>
          <tr>
            <th>Doctor Name</th>
            <th>Location</th>
            <th>Address</th>
            <th>Phone Number</th>
            <th>Full Name</th>
            <th>Session Number</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredAppointments.map((appointment) => (
            <tr key={appointment.id}>
              <td>{appointment.DoctorName}</td>
              <td>{appointment.location}</td>
              <td>{appointment.address}</td>
              <td>{appointment.phone_number}</td>
              <td>{appointment.full_name}</td>
              <td>{appointment.session_number}</td>
              <td>{appointment.Session_date}</td>
              <td>
                <button
                  onClick={() => handleRemoveAppointment(appointment)}
                  disabled={removing}
                >
                  {removing ? (
                    "Removing..."
                  ) : (
                    <FaTrash className="delete-icon" />
                  )}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AppointmentRemove;
