import React, { useEffect, useState } from "react";
import { Card, Button, Input } from "antd";
import axios from "axios";
import "./alldoctoruser.css";

import { useNavigate } from "react-router-dom";

const { Search } = Input;

const AllDoctorUser = () => {
  const navigate = useNavigate();
  const [doctors, setDoctors] = useState([]);
  const [filteredDoctors, setFilteredDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const token = localStorage.getItem("token");

        const response = await axios.get(
          "https://api.helpinghandlk.com/api/user/get-all-doctors",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          setDoctors(response.data.data);
          setFilteredDoctors(response.data.data);
        } else {
          console.log("Error fetching doctors:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching doctors:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDoctors();
  }, []);

  // Example function for booking
  const handleBookNow = (DoctorId) => {
    console.log("Book Now for doctor with ID:", DoctorId);
    // Redirect to AllSesioon_user with doctorId as state
    navigate("/AllSesioon_user", { state: { DoctorId } });
  };

  const handleSearch = (value) => {
    setSearchTerm(value);

    const filtered = doctors.filter((doctor) => {
      const name = doctor && doctor.name ? doctor.name.toLowerCase() : "";
      const description =
        doctor && doctor.description ? doctor.description.toLowerCase() : "";
      const adjustedFrom =
        doctor && doctor.adjustedFrom ? doctor.adjustedFrom.toLowerCase() : "";

      return (
        name.includes(value.toLowerCase()) ||
        description.includes(value.toLowerCase()) ||
        adjustedFrom.includes(value.toLowerCase())
      );
    });

    setFilteredDoctors(filtered);
  };

  const loadDoctorImage = (imageData) => {
    const imageDataArray = imageData.data.data;
    const uint8Array = new Uint8Array(imageDataArray);
    const blob = new Blob([uint8Array], { type: imageData.contentType });

    return URL.createObjectURL(blob); // return the object URL of the blob
  };

  return (
    <div className="card-wrapper container">
      <Search
        placeholder="Search by name, description, or date"
        onChange={(e) => handleSearch(e.target.value)}
        value={searchTerm}
        style={{ marginBottom: "20px" }}
      />
    
      {filteredDoctors.map((doctor) => (
        <Card key={doctor.id} style={{ marginBottom: "20px" }}>
          <div className="row">
            <div className="col">
              <div className="Doc-image-div">
                <div>
                  <h2 className="title">{doctor.name}</h2>
                </div>
                {doctor.image ? (
                  <img
                    alt="Doctor"
                    className="doctor-image"
                    src={loadDoctorImage(doctor.image)} // set src with the result of loadDoctorImage
                    title="Doctor Image"
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="col">
              <div className="person-name">
                <p id="postID">Description </p>
                <p>{doctor.description}</p>
              </div>
            </div>
            <div className="col button-div">
              <Button
                type="primary"
                onClick={() => handleBookNow(doctor.id)}
                style={{ padding: "3px 15px", alignSelf: "flex-start", }}
              >
                Book Now
              </Button>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default AllDoctorUser;
