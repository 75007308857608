import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./Single.css"; // Import the CSS file

const SingleBlogPage = () => {
  const [blog, setBlog] = useState(null);
  const { blogId } = useParams();

  useEffect(() => {
    console.log("blogId", blogId);

    axios
      .get(`https://api.helpinghandlk.com/api/user/get-blog-by-id/${blogId}`)
      .then((response) => {
        setBlog(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching blog:", error);
      });
  }, [blogId]);

  if (!blog) {
    // You might want to add a loading state or redirect to a 404 page
    return <div>Loading...</div>;
  }

  return (
    <div className="single-blog">
      <h2>{blog.blogTitle}</h2>
      <div className="blog-info">
        <p>Author: {blog.blogAuthor}</p>
        <p>Date: {blog.blogDate}</p>
      </div>
      <img
        src={`data:${blog.blogImage.contentType};base64,${blog.blogImage.data}`}
        alt={blog.blogTitle}
      />
      <div className="blog-description" dangerouslySetInnerHTML={{ __html: blog.blogDescription }} />
    </div>
  );
};

export default SingleBlogPage;
