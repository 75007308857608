
import React, { useState } from "react";
import { Form, Input, Button, Upload, Modal, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import "./AddDoctor.css";

const AddDoctor = () => {
  const [fileList, setFileList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state

  const onFinish = async (values) => {
    try {
      setLoading(true); // Set loading to true when submitting form
      const token = localStorage.getItem("token");

      // Create a FormData object to handle file uploads
      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });
      fileList.forEach((file) => {
        formData.append("image", file.originFileObj);
      });

      // Use the token in the axios.post request
      const response = await axios.post(
        "https://api.helpinghandlk.com/api/admin/create-doctor",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // Set content type for file uploads
          },
        }
      );

      if (response.data.success) {
        console.log("Doctor created successfully!");
        setModalVisible(true); // Show modal on successful doctor creation
        // Handle success, e.g., redirect or show a success message
      } else {
        console.log("Error creating doctor:");
        // Handle error, e.g., show an error message
      }
    } catch (error) {
      console.error("Something went wrong:", error);
    } finally {
      setLoading(false); // Set loading back to false after form submission completes
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFileChange = ({ fileList }) => {
    setFileList(fileList);
  };

  return (
    <div>
      <Spin spinning={loading}>
        {" "}
        {/* Display a loading spinner while loading */}
        <Form layout="vertical" onFinish={onFinish} className="containerr">
          <Form.Item
            label="Name of the Doctor"
            name="name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Doctor's Description"
            name="description"
            rules={[{ required: true, message: "Description is required" }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            label="Specification "
            name="spesification"
            rules={[{ required: true, message: "spesification is required" }]}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            label="Upload Image"
            name="image"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: "Image is required" }]}
          >
            <Upload
              beforeUpload={() => false}
              listType="picture"
              onChange={onFileChange}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={loading}>
              {" "}
              {/* Disable button while loading */}
              Create Doctor
            </Button>
          </Form.Item>
        </Form>
        <Modal
          title="Doctor Added Successfully"
          visible={modalVisible}
          onOk={() => setModalVisible(false)}
          onCancel={() => setModalVisible(false)}
        >
          <p>Your doctor has been added successfully!</p>
        </Modal>
      </Spin>
    </div>
  );
};

export default AddDoctor;
