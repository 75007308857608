import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from './useAuth';

const ProtectedRoute = ({ element }) => {
  const { isAuthenticated, loading } = useAuth();

  console.log("ProtectedRoute - Is Authenticated:", isAuthenticated);
  console.log("ProtectedRoute - Loading:", loading);

  if (loading) {
   
    return <div>Loading...</div>;
  }

  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
