import React from 'react';
import './gallery.css';
import g1 from '../../Assets/g1.JPG';
import g2 from '../../Assets/g2.JPG';
import g3 from '../../Assets/g3.JPG';
import g4 from '../../Assets/g4.JPG';

const Gallery = () => {
  const events = [
    {
      id: 1,
      images: [
        g1,
        g2,
        g3,
        g4
      ]
    },
    {
      id: 2,
      images: [
        'https://via.placeholder.com/400x300.png?text=Event+2+Image+1',
        'https://via.placeholder.com/400x300.png?text=Event+2+Image+2',
        'https://via.placeholder.com/400x300.png?text=Event+2+Image+3'
      ]
    }
  ];

  return (
    <div className="gallery">
      {events.map(event => (
        <div key={event.id} className="event">
          {event.images.map((image, index) => (
            <img key={index} src={image} alt={`Event ${event.id} Image ${index + 1}`} className="event-image" />
          ))}
        </div>
      ))}
    </div>
  );
};

export default Gallery;
