import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const EditDoctor = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { doctorId } = useParams();

  useEffect(() => {
    const fetchDoctorDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://api.helpinghandlk.com/api/admin/fetch-doctor/${doctorId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.data.success) {
          const doctorDetails = response.data.data;

          form.setFieldsValue({
            name: doctorDetails.name,
            description: doctorDetails.description,
            spesification: doctorDetails.spesification,
          });

          if (doctorDetails.image && doctorDetails.image.data && doctorDetails.image.contentType) {
            setFileList([
              {
                uid: '1',
                name: 'existingImage',
                status: 'done',
                url: `data:${doctorDetails.image.contentType};base64,${doctorDetails.image.data}`,
              },
            ]);
          }

          form.setFieldsValue({
            isbook: doctorDetails.isbook,
          });
        } else {
          console.log('Error fetching doctor details:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching doctor details:', error);
      }
    };

    if (doctorId) {
      fetchDoctorDetails();
    }
  }, [doctorId, form]);

  const onFinish = async (values) => {
    try {
      setLoading(true); // Show loading screen
      setErrorMessage('');

      const token = localStorage.getItem('token');
      const formData = new FormData();

      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });

      fileList.forEach((file) => {
        if (file.originFileObj) {
          formData.append('image', file.originFileObj);
        }
      });

      const response = await axios.put(`https://api.helpinghandlk.com/api/admin/update-doctor-profile/${doctorId}`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Doctor updated successfully!',
        });
        form.resetFields(); // Clear form fields on success
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      setErrorMessage('Something went wrong.');
      console.error('Something went wrong:', error);
    } finally {
      setLoading(false); // Hide loading screen
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFileChange = ({ fileList }) => {
    setFileList(fileList);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      className="container"
    >
      <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required' }]}>
        <Input />
      </Form.Item>

      <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Description is required' }]}>
        <Input.TextArea />
      </Form.Item>

      <Form.Item label="Spesification" name="spesification" rules={[{ required: true, message: 'Spesification is required' }]}>
        <Input.TextArea />
      </Form.Item>

      <Form.Item
        label="Upload Image"
        name="image"
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <Upload
          beforeUpload={() => false}
          listType="picture"
          onChange={onFileChange}
        >
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </Form.Item>

      {errorMessage && (
        <div style={{ color: 'red' }}>{errorMessage}</div>
      )}

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Update Doctor
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditDoctor;
