import React from 'react';
import './Behavioral.css';

const Behavioral = () => {
  return (
    <div className="residential-treatment-container">
      <div className="main-title">Behavioral Therapy at The Helping Hand Psychological Hospital</div>
      <div className="paragraph">
        At The Helping Hand Psychological Hospital, we offer comprehensive behavioral therapy services to help individuals address a wide range of psychological concerns. Our experienced therapists utilize evidence-based techniques to foster positive behavioral changes and improve overall mental well-being.
      </div>

      <div className="sub-title">What is Behavioral Therapy?</div>
      <div className="paragraph">
        Behavioral therapy focuses on identifying and modifying problematic behaviors through the application of learning principles. It operates under the assumption that all behaviors are learned and can be changed through systematic interventions.
      </div>

      <div className="sub-title">How Behavioral Therapy Works:</div>
      <ul className="bullet-points">
        <li>Identify and analyze problem behaviors</li>
        <li>Develop specific and achievable goals</li>
        <li>Learn new adaptive behaviors</li>
        <li>Reinforce desirable behaviors</li>
        <li>Address underlying cognitive and emotional factors</li>
      </ul>

      <div className="sub-title">Benefits of Behavioral Therapy:</div>
      <ul className="bullet-points">
        <li>Improved behavioral patterns</li>
        <li>Increased self-control</li>
        <li>Enhanced social skills</li>
        <li>Reduced anxiety and stress</li>
        <li>Improved mood and motivation</li>
      </ul>

      <div className="sub-title">Conditions We Treat:</div>
      <ul className="bullet-points">
        <li>Anxiety disorders (e.g., panic disorder, social phobia)</li>
        <li>Mood disorders (e.g., depression, bipolar disorder)</li>
        <li>Eating disorders</li>
        <li>Substance use disorders</li>
        <li>Post-traumatic stress disorder (PTSD)</li>
        <li>Attention-deficit/hyperactivity disorder (ADHD)</li>
      </ul>

      <div className="paragraph">
        <strong>Personalized Treatment Plans:</strong> Our therapists work closely with clients to create personalized treatment plans that address their specific needs and goals. We provide individual, group, and family therapy sessions, tailoring our approach to each client's unique situation.
      </div>

      <div className="paragraph">
        <strong>Contact Us:</strong> If you or a loved one is struggling with behavioral concerns, contact The Helping Hand Psychological Hospital today. Our compassionate therapists are here to provide support and guidance on your path to recovery.
      </div>
    </div>
  );
};

export default Behavioral;
