import React from "react";

const Signup = () => {

  return(

    <h1>
      Login
    </h1>

  )

}

export default Signup