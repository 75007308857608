import React from 'react';
import './Residential.css';

const ResidentialTreatment = () => {
  return (
    <div className="residential-treatment-container">
      <div className="main-title">Residential Treatment Facility at The Helping Hand Psychological Hospital</div>
      <div className="paragraph">
        At The Helping Hand Psychological Hospital, our Residential Treatment Facility (RTF) offers a safe and supportive environment for individuals struggling with mental health conditions who require intensive care and support.
      </div>

      <section>
        <div className="section-title">Our Mission</div>
        <div className="sub-title">The RTF is dedicated to helping individuals:</div>
        <div className="list-item">Overcome mental health challenges</div>
        <div className="list-item">Develop coping mechanisms</div>
        <div className="list-item">Achieve their treatment goals</div>
        <div className="list-item">Return to a stable and fulfilling life</div>
      </section>

      <section>
        <div className="section-title">Our Approach</div>
        <div className="paragraph">Our comprehensive treatment approach includes:</div>
        <div className="list-item"><strong>Individual therapy:</strong> One-on-one sessions that address individual needs and goals</div>
        <div className="list-item"><strong>Group therapy:</strong> Sessions that provide a supportive and interactive environment for growth</div>
        <div className="list-item"><strong>Medication management:</strong> Prescribed medications to manage symptoms and enhance mood stability</div>
        <div className="list-item"><strong>Psychiatric assessments:</strong> Regular evaluations to monitor progress and adjust treatment plans</div>
        <div className="list-item"><strong>Skill-building workshops:</strong> Classes that teach practical coping mechanisms, social skills, and life management strategies</div>
        <div className="list-item"><strong>Recreational activities:</strong> Structured activities that promote socialization, relaxation, and positive coping</div>
      </section>

      <section>
        <div className="section-title">Our Facility</div>
        <div className="paragraph">The RTF is located in a tranquil and therapeutic setting. Our facilities include:</div>
        <div className="list-item">Comfortable private and semi-private rooms</div>
        <div className="list-item">Common areas for socializing and group activities</div>
        <div className="list-item">A dining room with nutritious meals</div>
        <div className="list-item">A library with therapeutic materials</div>
        <div className="list-item">Access to outdoor spaces</div>
      </section>

      <section>
        <div className="section-title">Duration of Treatment</div>
        <div className="paragraph">The length of stay in the RTF varies depending on individual needs and progress. Typically, treatment lasts between 15 days and since livelihood.</div>
      </section>

      <section>
        <div className="section-title">Eligibility</div>
        <div className="paragraph">Admission to the RTF is based on a comprehensive assessment and is open to individuals who:</div>
        <div className="list-item">Are experiencing significant mental health distress</div>
        <div className="list-item">Require intensive support and stabilization</div>
        <div className="list-item">Are motivated to participate in treatment</div>
      </section>

      <section>
        <div className="section-title">Admissions Process</div>
        <div className="paragraph">To inquire about admission, please contact our intake team at 094719001212, 94117561122, or thehelpinghandhospital18@gmail.com. Our team will provide additional information and schedule an assessment.</div>
      </section>

      <div className="closing-remark">
        We are committed to providing individuals with the compassionate care and support they need to achieve mental well-being. Let our Residential Treatment Facility be your guiding hand on your journey toward recovery.
      </div>
    </div>
  );
};

export default ResidentialTreatment;
