import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Button } from "antd";
import html2pdf from "html2pdf.js";
import logo from "../../Assets/logo-removebg-preview.png"; // Import logo
import './Invoice.css'; // Import custom styles

const Invoice = () => {
  const { sessionId } = useParams();
  const [invoiceData, setInvoiceData] = React.useState({
    Price: 0,
    Session_date: "",
    DoctorName: "",
    location: "",
    starttime: "",
    endtime: "",
    address: "",
    phone_number: "",
    full_name: "",
    session_number: 0,
  });

  const pdfContainerRef = useRef();

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://api.helpinghandlk.com/api/user/get-session-by-id/${sessionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          // Update the state with fetched session data
          setInvoiceData(response.data.data);
        } else {
          console.error(
            "Failed to fetch session data:",
            response.data.message
          );
        }
      } catch (error) {
        console.error("Error fetching session data:", error);
      }
    };

    fetchSessionData();
  }, [sessionId]);

  const downloadInvoice = () => {
    // Get the HTML content from the ref
    const content = pdfContainerRef.current;

    // Create a container element to hold the invoice content
    const container = document.createElement("div");

    // Apply styles directly to the HTML content
    const styles = `
      <style>
        /* Add your CSS styles here */
        .container {
          padding: 20px;
          max-width: 800px;
          margin: 0 auto;
        }

        .header {
          display: flex;
          margin-left: 50px;
          align-items: center;
          margin-bottom: 20px;
        }

        .logo {
          width: 100px;
          height: auto;
        }

        .company-details {
          margin-left: 20px;
        }

        .company-name {
          font-size: 24px;
          margin-bottom: 5px;
        }

        .company-address,
        .company-contact {
          font-size: 16px;
          margin: 0;
        }

        .invoice-content {
          padding: 20px;
          background-color: #fff;
        }

        .invoice-content h2 {
          text-align: center;
          margin-bottom: 20px;
        }

        .invoice-details {
          font-size: 16px;
        }

        .invoice-details p {
          margin: 10px 0;
        }

        .invoice-details strong {
          font-weight: bold;
        }
      </style>
    `;

    // Create the company details HTML
    const companyDetails = `
      <div class="header">
        <img src="${logo}" alt="Company Logo" class="logo" />
        <div class="company-details">
          <h1 class="company-name">THE HELPING HAND (Psychological Hospital)</h1>
          <p class="company-address">No. 102/A Taladuwa Rd, Negombo</p>
          <p class="company-contact">Contact Numbers: 071 900 1212, 011 756 1122</p>
        </div>
      </div>
    `;

    // Append the styles and company details to the container
    container.innerHTML = styles + companyDetails;

    // Append the invoice content to the container
    container.appendChild(content.cloneNode(true));

    // Remove the download button from the container before generating the PDF
    const downloadButton = container.querySelector(".invoice-actions");
    if (downloadButton) {
      downloadButton.remove();
    }

    // Create a PDF with html2pdf, including the container content
    html2pdf().from(container.innerHTML).set({
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }
    }).save(`invoice_${sessionId}.pdf`);
  };

  return (
    <div className="container">
      {/* Company Logo and Name */}
      <div className="header">
        <img src={logo} alt="Company Logo" className="logo" />
        <div className="company-details">
          <h1 className="company-name">THE HELPING HAND (Psychological Hospital)</h1>
          <p className="company-address">No. 102/A Taladuwa Rd, Negombo</p>
          <p className="company-contact">Contact Numbers: 071 900 1212, 011 756 1122</p>
        </div>
      </div>

      <div ref={pdfContainerRef} className="invoice-content">
        <h2>Invoice</h2>
        <div className="invoice-details">
          <p><strong>Price:</strong> {invoiceData.Price}</p>
          <p><strong>Session Date:</strong> {invoiceData.Session_date}</p>
          <p><strong>Doctor Name:</strong> {invoiceData.DoctorName}</p>
          <p><strong>Location:</strong> {invoiceData.location}</p>
          <p><strong>Start Time:</strong> {invoiceData.starttime}</p>
          <p><strong>End Time:</strong> {invoiceData.endtime}</p>
          <p><strong>Address:</strong> {invoiceData.address}</p>
          <p><strong>Phone Number:</strong> {invoiceData.phone_number}</p>
          <p><strong>Full Name:</strong> {invoiceData.full_name}</p>
          <p><strong>Session Number:</strong> {invoiceData.session_number}</p>
        </div>

        <div className="invoice-actions">
          {/* The download button */}
          <Button type="primary" onClick={downloadInvoice}>
            Download Invoice
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
