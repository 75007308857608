

import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2"; // Import SweetAlert2
import "./AllSessionAdmin.css";

const AllSessionAdmin = () => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterDoctorName, setFilterDoctorName] = useState(null);
  const [filterLocation, setFilterLocation] = useState(null);
  const [filterSessionDate, setFilterSessionDate] = useState(null);

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://api.helpinghandlk.com/api/admin/get-all-sessions",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          setSessions(response.data.data);
        } else {
          console.log("Error fetching sessions:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching sessions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
  }, []);

  const handleDeleteSession = async (session) => {
    try {
      // Set loading state for the session being deleted
      setSessions((prevSessions) =>
        prevSessions.map((s) =>
          s.id === session.id ? { ...s, loading: true } : s
        )
      );

      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `https://api.helpinghandlk.com/api/admin/delete-sessions-by-criteria/${session.Doctorid}/${session.Session_date}/${session.location}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        console.log("Session deleted successfully:", response.data.message);
        setSessions((prevSessions) =>
          prevSessions.filter((s) => s.id !== session.id)
        );
        // Show success message using SweetAlert
        Swal.fire({
          icon: "success",
          title: "Session Deleted",
          text: response.data.message,
        });
      } else {
        console.log("Error deleting session:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting session:", error);
    }
  };

  const handleDeleteSessionById = async (sessionId) => {
    try {
      // Set loading state for the session being deleted
      setSessions((prevSessions) =>
        prevSessions.map((s) =>
          s.id === sessionId ? { ...s, loading: true } : s
        )
      );

      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `https://api.helpinghandlk.com/api/admin/delete-session-by-one/${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        console.log("Session deleted successfully:", response.data.message);
        setSessions((prevSessions) =>
          prevSessions.filter((s) => s.id !== sessionId)
        );
        // Show success message using SweetAlert
        Swal.fire({
          icon: "success",
          title: "Session Deleted",
          text: response.data.message,
        });
      } else {
        console.log("Error deleting session:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting session:", error);
    }
  };

  const filteredSessions = sessions.filter((session) => {
    return (
      (!filterDoctorName || session.DoctorName === filterDoctorName) &&
      (!filterLocation || session.location === filterLocation) &&
      (!filterSessionDate || session.Session_date === filterSessionDate)
    );
  });

  return (
    <div className="container">
      {/* Loading indicator */}
      {loading && <div>Loading...</div>}

      <div className="filter">
        <label>Filter by Doctor Name:</label>
        <select
          onChange={(e) => setFilterDoctorName(e.target.value)}
          value={filterDoctorName || ""}
        >
          <option value="">All</option>
          {Array.from(
            new Set(sessions.map((session) => session.DoctorName))
          ).map((doctorName, index) => (
            <option key={index} value={doctorName}>
              {doctorName}
            </option>
          ))}
        </select>
      </div>
      <div className="filter">
        <label>Filter by Location:</label>
        <select
          onChange={(e) => setFilterLocation(e.target.value)}
          value={filterLocation || ""}
        >
          <option value="">All</option>
          {Array.from(new Set(sessions.map((session) => session.location))).map(
            (location, index) => (
              <option key={index} value={location}>
                {location}
              </option>
            )
          )}
        </select>
      </div>
      <div className="filter">
        <label>Filter by Session Date:</label>
        <select
          onChange={(e) => setFilterSessionDate(e.target.value)}
          value={filterSessionDate || ""}
        >
          <option value="">All</option>
          {Array.from(
            new Set(sessions.map((session) => session.Session_date))
          ).map((sessionDate, index) => (
            <option key={index} value={sessionDate}>
              {sessionDate}
            </option>
          ))}
        </select>
      </div>

      <table className="sessions-table">
        <thead>
          <tr>
            <th>Session Number</th>
            <th>Location</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Session Date</th>
            <th>Doctor Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredSessions.map((session) => (
            <tr key={session.id}>
              <td>{session.session_number}</td>
              <td>{session.location}</td>
              <td>{session.starttime}</td>
              <td>{session.endtime}</td>
              <td>{session.Session_date}</td>
              <td>{session.DoctorName}</td>
              <td>
                <button
                  disabled={session.loading}
                  onClick={() => handleDeleteSession(session)}
                >
                  {session.loading ? "Deleting..." : "Delete"}
                </button>
                <button
                  disabled={session.loading}
                  onClick={() => handleDeleteSessionById(session.id)}
                >
                  {session.loading ? "Deleting..." : "Delete by ID"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllSessionAdmin;
