import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./AdminDashboard.css";

const AdminDashboard = () => {
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsAdminLoggedIn(!!token);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAdminLoggedIn(false);
    navigate("/login", { replace: true }); 
  };
  return (
    <div>
      <div className="admin-dashboard ">
        <h1>Welcome to Admin Dashboard</h1>

        <div className="dashboard-grid">
    
          <Link to="/add-doctor" className="dashboard-card">
            <h2>Add Doctor</h2>
            <p>Add New Doctors to System</p>
          </Link>

    
          <Link to="/all-doctor" className="dashboard-card">
            <h2>All Doctors</h2>
            <p>Edit/Delect Doctors</p>
          </Link>

  
          <Link to="/CreateSessionForm" className="dashboard-card">
            <h2>Add sessions </h2>
            <p>Add New Sessions for Doctors</p>
          </Link>

      
          <Link to="/AllSession-Admin" className="dashboard-card">
            <h2>All Sessions</h2>
            <p>Remove Sessions</p>
          </Link>

          <Link to="/AppointmentRemove" className="dashboard-card">
            <h2>Remove Appoinments</h2>
            <p>Remove User Appoinments</p>
          </Link>
          <Link to="/AllBlogAdmin" className="dashboard-card">
            <h2>Blogs</h2>
            <p>Edit delect blog</p>
          </Link>
          <Link to="/AddBlog" className="dashboard-card">
            <h2>Add Blogs</h2>
            <p>Add new blogs</p>
          </Link>
        </div>
        <div className="container logout-div">
          <button onClick={handleLogout}>Logout</button>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
