import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Modal, Spin } from "antd"; // Import Spin for loading indicator
import "./sessionform.css";

const CreateSessionForm = () => {
  const [sessionData, setSessionData] = useState({
    Price: "",
    Session_date: "",
    Doctorid: "",
    Patient_count: "",
    location: "",
    starttime: "",
    endtime: "",
    DoctorName: "",
  });

  const [doctors, setDoctors] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false); // State to track loading status

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        console.log("Submitting data:", sessionData);
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://api.helpinghandlk.com/api/admin/get-all-doctors",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          setDoctors(response.data.data);
        } else {
          console.error("Failed to fetch doctors:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching doctors:", error);
      }
    };

    fetchDoctors();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(`Name: ${name}, Value: ${value}`);

    if (name === "Doctorid") {
      const selectedDoctor = doctors.find((doctor) => doctor.id === value);

      setSessionData((prevData) => ({
        ...prevData,
        [name]: value,
        DoctorName: selectedDoctor ? selectedDoctor.name : "",
      }));
    } else {
      setSessionData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true); // Set loading state to true when form is being submitted
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "https://api.helpinghandlk.com/api/admin/create-Sessions",
        sessionData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        const result = response.data;
        console.log(result);
        message.success("Session created successfully"); // Display success message
        setModalVisible(true); // Show modal on successful session creation
      } else {
        console.error("Failed to create session:", response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Set loading state to false when submission is complete
    }
  };

  return (
    <div>
      <Spin spinning={loading}>
        <form onSubmit={handleSubmit} className="container">
          <label>
            Price:
            <input
              type="text"
              name="Price"
              value={sessionData.Price}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Session Date:
            <input
              type="date"
              name="Session_date"
              value={sessionData.Session_date}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Doctor ID:
            <select
              required
              name="Doctorid"
              value={sessionData.Doctorid}
              onChange={handleInputChange}
              className="doctor-select"
              style={{ color: "black", backgroundColor: "white" }}
            >
              <option value="" disabled>
                Select Doctor
              </option>
              {doctors.length === 1 && (
                <option value={doctors[0].id}>{doctors[0].name}</option>
              )}
              {doctors.length > 1 &&
                doctors.map((doctor) => (
                  <option key={doctor.id} value={doctor.id}>
                    {doctor.name}
                  </option>
                ))}
            </select>
          </label>
          <label>
            Patient Count:
            <input
              type="number"
              name="Patient_count"
              value={sessionData.Patient_count}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            Location:
            <input
              type="text"
              name="location"
              value={sessionData.location}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            Start Time:
            <input
              type="time"
              name="starttime"
              value={sessionData.starttime}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            End Time:
            <input
              type="time"
              name="endtime"
              value={sessionData.endtime}
              onChange={handleInputChange}
              required
            />
          </label>
          <button type="submit">Create Session</button>
        </form>
        <Modal
          title="Session Added Successfully"
          visible={modalVisible}
          onOk={() => setModalVisible(false)}
          onCancel={() => setModalVisible(false)}
        >
          <p>Your session has been added successfully!</p>
        </Modal>
      </Spin>
      {/* <Modal
        title="Session created successfully"
        visible={modalVisible}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
      >
        <p>Your session has been created successfully!</p>
      </Modal> */}
      {/* Conditional rendering of loading indicator */}
      {/* {loading && (
        <div className="loading-overlay">
          <Spin size="large" />
        </div>
      )} */}
    </div>
  );
};

export default CreateSessionForm;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import "./sessionform.css"

// const CreateSessionForm = () => {
//   const [sessionData, setSessionData] = useState({
//     Price: '',
//     Session_date: '',
//     Doctorid: '',
//     Patient_count: '',
//     location: '',
//     starttime: '',
//     endtime: '',
//     DoctorName: '',
//   });

//   const [doctors, setDoctors] = useState([]);

//   useEffect(() => {

//     const fetchDoctors = async () => {
//       try {
//         console.log('Submitting data:', sessionData);
//         const token = localStorage.getItem('token');
//         const response = await axios.get('https://api.helpinghandlk.com/api/admin/get-all-doctors', {
//           headers: {
//             'Authorization': `Bearer ${token}`,
//           },
//         });

//         if (response.data.success) {
//           setDoctors(response.data.data);
//         } else {
//           console.error('Failed to fetch doctors:', response.data.message);
//         }
//       } catch (error) {
//         console.error('Error fetching doctors:', error);
//       }
//     };

//     fetchDoctors();
//   }, []);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     console.log(`Name: ${name}, Value: ${value}`);

//     if (name === 'Doctorid') {

//       const selectedDoctor = doctors.find(doctor => doctor.id === value);

//       setSessionData((prevData) => ({
//         ...prevData,
//         [name]: value,
//         DoctorName: selectedDoctor ? selectedDoctor.name : '',
//       }));
//     } else {

//       setSessionData((prevData) => ({
//         ...prevData,
//         [name]: value,
//       }));
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const token = localStorage.getItem('token');
//       const response = await axios.post(
//         'https://api.helpinghandlk.com/api/admin/create-Sessions',
//         sessionData,
//         {
//           headers: {
//             'Authorization': `Bearer ${token}`,
//           },
//         }
//       );

//       if (response.data.success) {
//         const result = response.data;
//         console.log(result);

//       } else {
//         console.error('Failed to create session:', response.data.message);
//       }
//     } catch (error) {
//       console.error('Error:', error);
//     }

//   };

//   return (
//     <form onSubmit={handleSubmit} className='container'>

//       <label>
//         Price:
//         <input type="text" name="Price" value={sessionData.Price} onChange={handleInputChange} />
//       </label>
//       <label>
//         Session Date:
//         <input type="date" name="Session_date" value={sessionData.Session_date} onChange={handleInputChange} />
//       </label>
//       <label>
//   Doctor ID:
//   <select
//   required
//   name="Doctorid"
//   value={sessionData.Doctorid}
//   onChange={handleInputChange}
//   className='doctor-select'
//   style={{ color: 'black', backgroundColor: 'white' }}
// >
//   <option value="" disabled>Select Doctor</option>
//   {doctors.length === 1 && (
//     <option value={doctors[0].id}>{doctors[0].name}</option>
//   )}
//   {doctors.length > 1 && doctors.map(doctor => (
//     <option key={doctor.id} value={doctor.id}>{doctor.name}</option>
//   ))}
// </select>

// </label>

//       <label>
//         Patient Count:
//         <input type="number" name="Patient_count" value={sessionData.Patient_count} onChange={handleInputChange} required/>
//       </label>
//       <label>
//         Location:
//         <input type="text" name="location" value={sessionData.location} onChange={handleInputChange} required/>
//       </label>
//       <label>
//         Start Time:
//         <input type="time" name="starttime" value={sessionData.starttime} onChange={handleInputChange} required/>
//       </label>
//       <label>
//         End Time:
//         <input type="time" name="endtime" value={sessionData.endtime} onChange={handleInputChange} required/>
//       </label>
//       <button type="submit">Create Session</button>
//     </form>
//   );
// };

// export default CreateSessionForm;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { message, Modal } from "antd";
// import "./sessionform.css";

// const CreateSessionForm = () => {
//   const [sessionData, setSessionData] = useState({
//     Price: "",
//     Session_date: "",
//     Doctorid: "",
//     Patient_count: "",
//     location: "",
//     starttime: "",
//     endtime: "",
//     DoctorName: "",
//   });

//   const [doctors, setDoctors] = useState([]);
//   const [modalVisible, setModalVisible] = useState(false);

//   useEffect(() => {
//     const fetchDoctors = async () => {
//       try {
//         console.log("Submitting data:", sessionData);
//         const token = localStorage.getItem("token");
//         const response = await axios.get(
//           "https://api.helpinghandlk.com/api/admin/get-all-doctors",
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         if (response.data.success) {
//           setDoctors(response.data.data);
//         } else {
//           console.error("Failed to fetch doctors:", response.data.message);
//         }
//       } catch (error) {
//         console.error("Error fetching doctors:", error);
//       }
//     };

//     fetchDoctors();
//   }, []);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     console.log(`Name: ${name}, Value: ${value}`);

//     if (name === "Doctorid") {
//       const selectedDoctor = doctors.find((doctor) => doctor.id === value);

//       setSessionData((prevData) => ({
//         ...prevData,
//         [name]: value,
//         DoctorName: selectedDoctor ? selectedDoctor.name : "",
//       }));
//     } else {
//       setSessionData((prevData) => ({
//         ...prevData,
//         [name]: value,
//       }));
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const token = localStorage.getItem("token");
//       const response = await axios.post(
//         "https://api.helpinghandlk.com/api/admin/create-Sessions",
//         sessionData,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (response.data.success) {
//         const result = response.data;
//         console.log(result);
//         message.success("Session created successfully"); // Display success message
//         setModalVisible(true); // Show modal on successful session creation
//       } else {
//         console.error("Failed to create session:", response.data.message);
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   return (
//     <div>
{
  /* <form onSubmit={handleSubmit} className="container">
  <label>
    Price:
    <input
      type="text"
      name="Price"
      value={sessionData.Price}
      onChange={handleInputChange}
    />
  </label>
  <label>
    Session Date:
    <input
      type="date"
      name="Session_date"
      value={sessionData.Session_date}
      onChange={handleInputChange}
    />
  </label>
  <label>
    Doctor ID:
    <select
      required
      name="Doctorid"
      value={sessionData.Doctorid}
      onChange={handleInputChange}
      className="doctor-select"
      style={{ color: "black", backgroundColor: "white" }}
    >
      <option value="" disabled>
        Select Doctor
      </option>
      {doctors.length === 1 && (
        <option value={doctors[0].id}>{doctors[0].name}</option>
      )}
      {doctors.length > 1 &&
        doctors.map((doctor) => (
          <option key={doctor.id} value={doctor.id}>
            {doctor.name}
          </option>
        ))}
    </select>
  </label>
  <label>
    Patient Count:
    <input
      type="number"
      name="Patient_count"
      value={sessionData.Patient_count}
      onChange={handleInputChange}
      required
    />
  </label>
  <label>
    Location:
    <input
      type="text"
      name="location"
      value={sessionData.location}
      onChange={handleInputChange}
      required
    />
  </label>
  <label>
    Start Time:
    <input
      type="time"
      name="starttime"
      value={sessionData.starttime}
      onChange={handleInputChange}
      required
    />
  </label>
  <label>
    End Time:
    <input
      type="time"
      name="endtime"
      value={sessionData.endtime}
      onChange={handleInputChange}
      required
    />
  </label>
  <button type="submit">Create Session</button>
</form> */
}
//       <Modal
//         title="Session created successfully"
//         visible={modalVisible}
//         onOk={() => setModalVisible(false)}
//         onCancel={() => setModalVisible(false)}
//       >
//         <p>Your session has been created successfully!</p>
//       </Modal>
//     </div>
//   );
// };

// export default CreateSessionForm;
