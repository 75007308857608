import React, { useEffect, useState } from 'react';
import { Table, Space, Button } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons'; // Import Edit, Delete, and Eye icons
import Swal from 'sweetalert2'; // Import Swal
import "./AllBlogAdmin.css";

const AllBlogAdmin = () => {
  const navigate = useNavigate();

  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const token = localStorage.getItem('token');

        const response = await axios.get('https://api.helpinghandlk.com/api/admin/get-all-blogs-admin', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success) {
          setBlogs(response.data.data);
          console.log(response.data.data)
        } else {
          console.log('Error fetching blogs:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const columns = [
    {
      title: 'Blog Title',
      dataIndex: 'blogTitle',
      key: 'blogTitle',
    },
    {
      title: 'Blog Description',
      dataIndex: 'blogDescription',
      key: 'blogDescription',
    },
    {
      title: 'Blog Author',
      dataIndex: 'blogAuthor',
      key: 'blogAuthor',
    },
    {
      title: 'Blog Date',
      dataIndex: 'blogDate',
      key: 'blogDate',
    },
   
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditOutlined style={{ fontSize: '20px', color: '#1890ff' }} onClick={() => handleEdit(record.id)} />
          <DeleteOutlined style={{ fontSize: '20px', color: '#ff4d4f' }} onClick={() => handleDelete(record.id)} />
          <EyeOutlined style={{ fontSize: '20px', color: '#52c41a' }} onClick={() => handleViewImage(record.blogImage)} />
        </Space>
      ),
    },
  ];

  const handleEdit = (blogId) => {
    navigate(`/editblog/${blogId}`);
    console.log('Edit blog with ID:', blogId);
  };

  const handleDelete = async (blogId) => {
    console.log(blogId)
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`https://api.helpinghandlk.com/api/admin/delete-blog/${blogId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        setBlogs((prevBlogs) => prevBlogs.filter(blog => blog.id !== blogId));
        console.log(response.data.message);
        // Show SweetAlert success notification
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Blog deleted successfully!',
        });
      } else {
        console.log('Error deleting blog:', response.data.message);
      }
    } catch (error) {
      console.error('Error deleting blog:', error);
      // Show SweetAlert error notification
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to delete blog!',
      });
    }
  };

  const handleViewImage = (blogImage) => {
    console.log("checkimge",`data:${blogImage.contentType};base64,${blogImage.data}`)
    Swal.fire({
      imageUrl: `data:${blogImage.contentType};base64,${blogImage.data}`,
      imageAlt: 'Blog Image',
    });
  };

  return (
    <div className='container'>
      <Table
        dataSource={blogs}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
};

export default AllBlogAdmin;
