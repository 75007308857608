import React, { useEffect, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FaGoogle } from 'react-icons/fa'; // Import the Google icon
import './Main.css'; // Import your CSS file
import axios from 'axios';
import AllDoctorUser from '../User/AllDoctorUser';

const Main = () => {
  const doctorsSectionRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [doctors, setDoctors] = useState([]);

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await axios.get('https://api.helpinghandlk.com/api/user/get-all-doctors');
     

        const doctorsArray = response.data.doctorDetails
        ? response.data.doctorDetails.map((doctor) => ({
            id: doctor.id,
            description: doctor.description,
            spesification: doctor.spesification,
            image: doctor.image.path,
            name: doctor.name,
          }))
        : [];
        setDoctors(doctorsArray);

      } catch (error) {
        console.error('Error fetching doctors: ', error);
      }
    };

    fetchDoctors();
  }, []);

  const scrollToDoctorsSection = () => {
    doctorsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleGetAppointment = () => {
    openModal();
  };



  return (
    <div>
      <div className="auth-container">
       
        <div className="image-section">
          <div className="image-container">
            <img src="/1.png" alt="Banner" className="image" />
            <div className="banner-text">
              <h1>Your<span className="bold-text"> Mental Health</span> Journey Starts Here</h1>
              <p>Book your appointment with ease!</p>
              <div className="scroll-arrow" onClick={scrollToDoctorsSection}>
                &#8595; {/* Unicode arrow down character */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="doctors-section" ref={doctorsSectionRef}>
        <h2>Meet Our Doctors</h2>
     
      </div>



      {/* <Dialog open={isModalOpen} onClose={closeModal}>
        <DialogTitle>Login/Sign Up</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please login or sign up to proceed.
          </DialogContentText> */}
          {/* Add your login and sign-up options here, e.g., Google login button */}
          {/* <Button variant="contained" color="primary" fullWidth startIcon={<FaGoogle />}>
          <a href="/login"> Login with Google</a>
          </Button>

          <button class="btn btn-primary my-2 my-sm-0 contactUsBtn">
          <a href="/session"> Make Appointment</a>
        </button>
        </DialogContent>

        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog> */}
      <AllDoctorUser/>
    </div>
  );
};

export default Main;