import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Diploma.css";

const Diploma = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <div className="diploma-container">
      <h1>Diploma Programs</h1>

      <div className="diploma-program">
        <h2>Diploma in Child Psychology</h2>
        <div className="program-details">
          <div className="program-module">
            <h3>Modules</h3>
            <ul>
              <li>Introduction to Child Development</li>
              <li>Theories of Child Development</li>
              <li>Cognitive Development in Children</li>
              <li>Social and Emotional Development in Children</li>
              <li>Language Development in Children</li>
              <li>Understanding Behavior in Children</li>
              <li>Assessing and Diagnosing Child Disorders</li>
              <li>Intervention and Treatment Strategies for Children</li>
              <li>Parenting and Family Dynamics</li>
              <li>Ethical and Legal Issues in Child Psychology</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="diploma-program">
        <h2>Diploma in Counseling Psychology</h2>
        <div className="program-details">
          <div className="program-module">
            <h3>Modules</h3>
            <ul>
              <li>Introduction to Counseling Psychology</li>
              <li>Theoretical Approaches to Counseling</li>
              <li>Counseling Techniques and Interventions</li>
              <li>Assessment and Diagnosis in Counseling</li>
              <li>Ethics and Professional Issues in Counseling</li>
              <li>Multicultural Counseling</li>
              <li>Group Counseling</li>
              <li>Career Counseling</li>
              <li>Substance Abuse and Addiction Counseling</li>
              <li>Family and Couples Counseling</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="diploma-details">
        <h2>Diploma Details</h2>
        <div className="details-content">
          <p><strong>Duration:</strong> One Year</p>
          <p><strong>Diploma Fee:</strong></p>
          <ul>
            <li>Sri Lankan Residents: LKR 80,000.00</li>
            <li>Foreign Students: USD 400</li>
          </ul>
          <p><strong>Clinical Training:</strong> At The Helping Hand Psychological Hospital</p>
        </div>
      </div>
    </div>
  );
};

export default Diploma;
