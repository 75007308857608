import React from 'react';
import './Training.css';

const Training = () => {
  return (
    <div className="residential-treatment-container">
      <div className="main-title">Training for Mental Health Professionals at The Helping Hand Psychological Hospital</div>
      <div className="paragraph">
        At The Helping Hand Psychological Hospital, we prioritize the continuous professional development of our team. We offer a comprehensive range of training programs tailored to the needs of various disciplines within the field of mental health.
      </div>

      <div className="sub-title">Our Training Offerings:</div>
      <ul className="bullet-points">
        <li>
          <strong>Clinical Skills Development:</strong> Enhancing assessment, diagnosis, and treatment techniques for diverse mental health conditions.
        </li>
        <li>
          <strong>Evidence-Based Practice Training:</strong> Training on the latest evidence-based approaches to mental health care, including cognitive-behavioral therapy, psychodynamic psychotherapy, and mindfulness-based interventions.
        </li>
        <li>
          <strong>Specialized Training:</strong> Targeted training on topics such as trauma-informed care, geriatric mental health, and substance use disorders.
        </li>
        <li>
          <strong>Cultural Competency Training:</strong> Developing cultural sensitivity and competence in providing culturally appropriate and responsive mental health care.
        </li>
        <li>
          <strong>Interdisciplinary Collaboration:</strong> Facilitating training that promotes collaboration between mental health professionals, medical staff, and community partners.
        </li>
      </ul>

      <div className="sub-title">Training Format and Delivery:</div>
      <ul className="bullet-points">
        <li>In-person workshops and conferences</li>
        <li>Online webinars and e-learning modules</li>
        <li>Supervised clinical experiences</li>
        <li>Hospital Clinical Internship Program (150 hours, 300 hours, 600 hours)</li>
      </ul>

      <div className="sub-title">Benefits of Training:</div>
      <ul className="bullet-points">
        <li>Improved clinical skills and knowledge</li>
        <li>Enhanced patient outcomes</li>
        <li>Increased professional credibility and expertise</li>
        <li>Staying abreast of the latest advancements in mental health care</li>
      </ul>

      <div className="sub-title">Target Audience:</div>
      <ul className="bullet-points">
        <li>Clinicians, therapists, and counselors</li>
        <li>Psychologists</li>
        <li>Psychiatrists</li>
        <li>Social workers</li>
        <li>Nurses and nurse practitioners</li>
        <li>Any Other Professionals</li>
      </ul>

      <div className="paragraph">
        To learn more about our training offerings and register, please contact our Professional Development Department at <a href="mailto:thehelpinghandhospital18@gmail.com">thehelpinghandhospital18@gmail.com</a>.
      </div>

      <div className="paragraph">
        By investing in the professional development of our team, The Helping Hand Psychological Hospital is committed to delivering exceptional mental health care and improving the lives of our patients.
      </div>
    </div>
  );
};

export default Training;
