import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Internships.css";

const Internships = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <div className="internships-container">
      <h1>Psychology Clinical Internship Program Syllabus</h1>

      <div className="program-details">
        <h2>150 Hours Program</h2>
        <div className="module">
          <h3>Module 1: Introduction to Clinical Psychology</h3>
          <ul>
            <li>Overview of clinical psychology</li>
            <li>Ethical considerations in clinical practice</li>
            <li>The role of psychology in healthcare</li>
          </ul>
        </div>
        <div className="module">
          <h3>Module 2: Assessment and Diagnosis</h3>
          <ul>
            <li>Psychometric testing and assessment tools</li>
            <li>Diagnostic criteria for mental health disorders</li>
            <li>Case formulation</li>
          </ul>
        </div>
        <div className="module">
          <h3>Module 3: Therapeutic Interventions</h3>
          <ul>
            <li>Cognitive-behavioral therapy techniques</li>
            <li>Mindfulness and relaxation techniques</li>
            <li>Group therapy skills</li>
          </ul>
        </div>
        <div className="module">
          <h3>Module 4: Professional Development</h3>
          <ul>
            <li>Opportunities in the field of clinical psychology</li>
            <li>Building strong therapeutic relationships</li>
            <li>Career paths in psychology</li>
          </ul>
        </div>
      </div>

      <div className="program-details">
        <h2>300 Hours Program</h2>
        <div className="module">
          <h3>Module 5: Advanced Assessment Techniques</h3>
          <ul>
            <li>Neuropsychological assessment</li>
            <li>Projective techniques</li>
            <li>Diagnostic interviewing skills</li>
          </ul>
        </div>
        <div className="module">
          <h3>Module 6: Specialized Populations</h3>
          <ul>
            <li>Working with children and adolescents</li>
            <li>Geriatric psychology</li>
            <li>Trauma and crisis intervention</li>
          </ul>
        </div>
        <div className="module">
          <h3>Module 7: Evidence-Based Practices</h3>
          <ul>
            <li>Empirically supported treatments</li>
            <li>Integrating research into clinical practice</li>
            <li>Ethical considerations in research</li>
          </ul>
        </div>
      </div>

      <div className="program-details">
        <h2>600 Hours Program</h2>
        <div className="module">
          <h3>Module 8: Supervised Clinical Practice</h3>
          <ul>
            <li>Hands-on experience in a clinical setting</li>
            <li>Supervised therapy sessions with patients</li>
            <li>Case presentations and peer feedback</li>
          </ul>
        </div>
        <div className="module">
          <h3>Module 9: Case Management</h3>
          <ul>
            <li>Developing treatment plans</li>
            <li>Collaboration with multidisciplinary teams</li>
            <li>Integrating theory into practice</li>
          </ul>
        </div>
        <div className="module">
          <h3>Module 10: Professional Competencies</h3>
          <ul>
            <li>Cultural competence in clinical practice</li>
            <li>Documentation and record-keeping</li>
            <li>Self-care and burnout prevention</li>
          </ul>
        </div>
      </div>

      <div className="payment-info">
        <h2>Payment Method</h2>
        <ul>
          <li>Cash payment is accepted at our hospital reception desk.</li>
          <li>Bank transfers can be made to our hospital's bank account. Please contact us for the account details.</li>
          <li>Account details:</li>
          <ul>
            <li>Account Number: 0085103852</li>
            <li>Bank: The Helping Hand</li>
            <li>Branch: Bank of Ceylon, Minuwangoda Branch, Sri Lanka</li>
          </ul>
          <li>Online payment through our hospital website is also available.</li>
        </ul>
      </div>

      <div className="program-fees">
        <h2>Program Fees</h2>
        <ul>
          <li>150-hour program: SLK 25,000.00 (USD 200 for Foreign Students)</li>
          <li>300-hour program: SLK 60,000.00 (USD 400 for Foreign Students)</li>
          <li>600-hour program: SLK 120,000.00 (USD 600 for Foreign Students)</li>
        </ul>
        <p>Please note that program fees are non-refundable once the program has commenced. Thank you for considering our Internship Program at The Helping Hand Psychological Hospital.</p>
      </div>
    </div>
  );
};

export default Internships;
