import React, { useState, useEffect } from "react";
import BlogCard from "./Blogcard";
import axios from "axios";
import { Link } from 'react-router-dom';
import './AllSumerizeblog.css'; 

const AllSumerizeblog = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get("https://api.helpinghandlk.com/api/user/get-all-blogs")
      .then(response => {
        setBlogs(response.data.data);
        console.log(response.data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error("Error fetching blogs:", error);
        setLoading(false); 
      });
  }, []);

  return (
    <div className="blog-container">
      {loading ? ( 
        <div className="loading-container">Loading...</div>
      ) : (
       
        blogs.map(blog => (
          <Link key={blog.id} to={`/blog/${blog.id}`} className="blog-link">
            <BlogCard blog={blog} className="blog-card" />
          </Link>
        ))
      )}
    </div>
  );
};

export default AllSumerizeblog;
