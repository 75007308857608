import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./HigherDiploma.css";

const HigherDiploma = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <div className="higher-diploma-container">
      <h1>High National Diploma in Special Needs Education</h1>

      <div className="course-info">
  <div className="course-details">
    <div className="course-detail-item">
      <p><strong>Institution:</strong> The Helping Hand Psychological Hospital</p>
    </div>
    <div className="course-detail-item">
      <p><strong>Course Duration:</strong> One and a half years</p>
    </div>
    <div className="course-detail-item course-fees">
      <p><strong>Course Fees:</strong></p>
      <ul>
        <li>Sri Lankan Residents: LKR 150,000.00</li>
        <li>Foreign Students: USD 600</li>
      </ul>
    </div>
  </div>
</div>


      <div className="course-overview">
        <h2>Course Overview</h2>
        <p>The High National Diploma in Special Needs Education provides students with the knowledge, skills, and competencies necessary to work effectively with individuals with special needs. The program covers various aspects of special needs education, including:</p>
        <ul>
          <li>Assessment and diagnosis of special needs</li>
          <li>Development of individualized education plans</li>
          <li>Teaching and learning strategies for students with special needs</li>
          <li>Classroom management and behavior support</li>
          <li>Collaboration with parents and professionals</li>
          <li>Advocacy for students with special needs</li>
        </ul>
      </div>

      <div className="syllabus">
        <h2>Syllabus</h2>
        <div className="module">
          <h3>Module 1: Introduction to Special Needs Education</h3>
          <ul>
            <li>Defining special needs</li>
            <li>Classifications of special needs</li>
            <li>Ethical and legal considerations</li>
            <li>Roles and responsibilities of special educators</li>
          </ul>
        </div>
        <div className="module">
          <h3>Module 2: Assessment and Diagnosis</h3>
          <ul>
            <li>Principles of assessment</li>
            <li>Screening and diagnostic tools</li>
            <li>Cognitive, academic, and behavioral assessments</li>
            <li>Interpretation and reporting of assessment results</li>
          </ul>
        </div>
        <div className="module">
          <h3>Module 3: Individualized Education Planning</h3>
          <ul>
            <li>Purpose and components of individualized education plans (IEPs)</li>
            <li>Developing and implementing IEPs</li>
            <li>Collaboration with parents and the IEP team</li>
          </ul>
        </div>
        <div className="module">
          <h3>Module 4: Teaching and Learning Strategies</h3>
          <ul>
            <li>Principles of teaching and learning for students with special needs</li>
            <li>Differentiated instruction</li>
            <li>Sensory integration</li>
            <li>Social and emotional learning</li>
            <li>Technology in special education</li>
          </ul>
        </div>
        <div className="module">
          <h3>Module 5: Classroom Management and Behavior Support</h3>
          <ul>
            <li>Principles of classroom management</li>
            <li>Positive behavior supports</li>
            <li>Functional behavior analysis</li>
            <li>Collaborative problem-solving</li>
          </ul>
        </div>
        <div className="module">
          <h3>Module 6: Collaboration with Parents and Professionals</h3>
          <ul>
            <li>Building relationships with parents</li>
            <li>Communication with parents and professionals</li>
            <li>Interdisciplinary team collaboration</li>
            <li>Advocacy for students with special needs</li>
          </ul>
        </div>
        <div className="module">
          <h3>Module 7: Transition Planning</h3>
          <ul>
            <li>Transition planning for students with special needs</li>
            <li>Supported living</li>
            <li>Vocational training</li>
            <li>Post-secondary education options</li>
          </ul>
        </div>
        <div className="module">
          <h3>Module 8: Professional Development</h3>
          <ul>
            <li>Ethical and professional standards</li>
            <li>Reflective practice</li>
            <li>Current trends in special needs education</li>
            <li>Continuing professional development</li>
          </ul>
        </div>
      </div>

      <div className="admission-requirements">
        <h2>Admission Requirements</h2>
        <ul>
          <li>High school diploma or equivalent qualification</li>
          <li>Demonstrated interest in working with individuals with special needs</li>
          <li>Strong communication and interpersonal skills</li>
        </ul>
      </div>

      <div className="career-prospects">
        <h2>Career Prospects</h2>
        <p>Graduates of the High National Diploma in Special Needs Education can pursue careers in various settings, including:</p>
        <ul>
          <li>Special education schools</li>
          <li>Mainstream schools with special needs units</li>
          <li>Early intervention centers</li>
          <li>Residential care facilities</li>
          <li>Advocacy organizations</li>
          <li>Healthcare settings</li>
        </ul>
      </div>
    </div>
  );
};

export default HigherDiploma;
