import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

const AllSessionUser = () => {
  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [uniqueDates, setUniqueDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  const filteredDoctorId = location.state?.DoctorId;

  const handleBookNow = (sessionId, isBook) => {
    if (!isBook) {
      navigate(`/CheckOutForm/${sessionId}`);
    }
  };

  const filterSessionsByDate = () => {
    const filteredSessions = sessions.filter(session => session.Session_date === selectedDate);
    setFilteredSessions(filteredSessions);
  };

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://api.helpinghandlk.com/api/user/get-all-sessions-user/${filteredDoctorId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success) {
          const sortedSessions = response.data.data.sort((a, b) => a.session_number - b.session_number);
          setSessions(sortedSessions);

          const uniqueDatesArray = Array.from(new Set(sortedSessions.map(session => session.Session_date)));
          const futureDates = uniqueDatesArray.filter(date => new Date(date) >= new Date().setHours(0, 0, 0, 0));
          setUniqueDates(futureDates);

          // Set the default selected date to the nearest future date
          const today = new Date().toISOString().split('T')[0];
          const nearestDate = futureDates.reduce((nearest, date) => {
            return Math.abs(new Date(date) - new Date(today)) < Math.abs(new Date(nearest) - new Date(today)) ? date : nearest;
          }, futureDates[0] || today);
          setSelectedDate(nearestDate);

          const initialFilteredSessions = sortedSessions.filter(session => session.Session_date === nearestDate);
          setFilteredSessions(initialFilteredSessions);
        } else {
          console.error('Failed to fetch sessions:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching sessions:', error);
      }
    };

    if (filteredDoctorId) {
      fetchSessions();
    }
  }, [filteredDoctorId]);

  useEffect(() => {
    filterSessionsByDate();
  }, [selectedDate]);

  return (
    <div className='container'>
      <div>
        <label>Doctor Available only for these Days:</label>
        <ul>
          {uniqueDates.map((date, index) => (
            <li key={index}>{date}</li>
          ))}
        </ul>
      </div>
      <div>
        <label>Date:</label>
        <input
          type='date'
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          min={new Date().toISOString().split('T')[0]}
        />
      </div>

      {filteredSessions.map(session => (
        <div key={session.id} className={`card mb-3 p-4 ${session.isBook ? 'booked' : ''}`}>
          <p>Starting Time: {session.starttime}</p>
          <p>Location: {session.location}</p>
          <p>Session Duration: 20 minutes</p>
          <p>Doctor Name: {session.DoctorName}</p>
          <p>Number: {session.session_number}</p>
          <p>Date: {session.Session_date}</p>

          {session.isBook ? (
            <p className="booked-message">Already Booked</p>
          ) : (
            <button className="book-button" onClick={() => handleBookNow(session.id, session.isBook)}>
              Book Now
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default AllSessionUser;
