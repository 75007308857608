import React from "react";
import "./blogc.css";

const BlogCard = ({ blog }) => {
  const BlogImage= `data:${blog.blogImage.contentType};base64,${blog.blogImage.data}`
  return (
    <div className="blog-card">
      <img
        className="blog-image"
        src={`${BlogImage}`}
        alt={blog.blogTitle}
      />
      <div className="blog-details">
        <h2 className="blog-title">{blog.blogTitle}</h2>
        <p className="blog-author">Author: {blog.blogAuthor}</p>
      </div>
    </div>
  );
};

export default BlogCard;
