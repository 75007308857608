import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from 'axios';
import Swal from 'sweetalert2';

const AddBlog = () => {
  const [blogTitle, setBlogTitle] = useState("");
  const [blogDescription, setBlogDescription] = useState("");
  const [blogAuthor, setBlogAuthor] = useState("");
  const [blogDate, setBlogDate] = useState(new Date().toISOString());
  const [blogImage, setBlogImage] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state

  const handleRichTextChange = (value) => {
    setBlogDescription(value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setBlogImage(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submitting

    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append('blogTitle', blogTitle);
      formData.append('blogDescription', blogDescription);
      formData.append('blogAuthor', blogAuthor);
      formData.append('blogDate', blogDate);
      formData.append('image', blogImage);
      console.log(formData);
      const response = await axios.post('https://api.helpinghandlk.com/api/admin/create-blog', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
     
      console.log(response.data);
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Blog added successfully!',
      }).then(() => {
        setBlogTitle("");
        setBlogDescription("");
        setBlogAuthor("");
        setBlogDate(new Date().toISOString());
        setBlogImage(null);
        setLoading(false); // Set loading to false after successful submission
      });

    } catch (error) {
      console.error('Error submitting blog:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
      setLoading(false); // Set loading to false if there's an error
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="container">
        <h3 className="">Add new Blog</h3>
        <label>
          Title:
          <input
            type="text"
            value={blogTitle}
            onChange={(e) => setBlogTitle(e.target.value)}
          />
        </label>
 
        <label>
          Description:
          <ReactQuill
            value={blogDescription}
            onChange={handleRichTextChange}
          />
        </label>
    
        <label>
          Author:
          <input
            type="text"
            value={blogAuthor}
            onChange={(e) => setBlogAuthor(e.target.value)}
          />
        </label>
      
        <label>
          Date:
          <input
            type="date"
            value={blogDate}
            onChange={(e) => setBlogDate(e.target.value)}
          />
        </label>
      
        <label>
          Image:
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </label>
      
        <button type="submit" disabled={loading}>Submit</button> {/* Disable button when loading */}
      </form>
    </div>
  );
};

export default AddBlog;
