import { useState, useEffect } from 'react';

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('token');
      console.log("Retrieved token:", token);

      if (token) {
        // Simulate async operation
        setTimeout(() => {
          console.log("Token exists, updating authentication state");
          setIsAuthenticated(true);
          setLoading(false); // Set loading to false once the state is updated
        }, 100); // Adjust timing if needed
      } else {
        setIsAuthenticated(false);
        setLoading(false); // Set loading to false once the state is updated
      }
    };

    checkAuth();
  }, []); // Empty dependency array to run only on mount

  return { isAuthenticated, loading };
};

export default useAuth;
