import React, { useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditBlog = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const { blogId } = useParams();

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://api.helpinghandlk.com/api/user/get-blog-by-id/${blogId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.data.success) {
          const blogDetails = response.data.data;

          form.setFieldsValue({
            blogTitle: blogDetails.blogTitle,
            blogDescription: blogDetails.blogDescription,
            blogAuthor: blogDetails.blogAuthor,
            blogDate: moment(blogDetails.blogDate), // Use moment to handle date
          });

          if (blogDetails.blogImage && blogDetails.blogImage.data && blogDetails.blogImage.contentType) {
            setFileList([
              {
                uid: '1',
                name: 'existingImage',
                status: 'done',
                url: `data:${blogDetails.blogImage.contentType};base64,${blogDetails.blogImage.data}`,
              },
            ]);
          }
        } else {
          console.log('Error fetching blog details:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching blog details:', error);
      }
    };

    if (blogId) {
      fetchBlogDetails();
    }
  }, [blogId, form]);

  const onFinish = async (values) => {
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();

      Object.entries(values).forEach(([key, value]) => {
        // Convert moment.js date to string before appending to FormData
        formData.append(key, key === 'blogDate' && value ? value.format('YYYY-MM-DD') : value);
      });

      fileList.forEach((file) => {
        if (file.originFileObj) {
          formData.append('image', file.originFileObj);
        }
      });

      const response = await axios.put(`https://api.helpinghandlk.com/api/admin/update-blog/${blogId}`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        message.success('Blog updated successfully!');
        // Handle success, e.g., redirect or show a success message
      } else {
        message.error('Error updating blog: ' + response.data.message);
      }
    } catch (error) {
      console.error('Something went wrong:', error);
      message.error('Something went wrong while updating the blog.');
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFileChange = ({ fileList }) => {
    setFileList(fileList);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      className="container"
    >
      <Form.Item label="Blog Title" name="blogTitle" rules={[{ required: true, message: 'Blog Title is required' }]}>
        <Input />
      </Form.Item>

      <Form.Item label="Blog Description" name="blogDescription" rules={[{ required: true, message: 'Blog Description is required' }]}>
        <ReactQuill
          theme="snow"
          value={form.getFieldValue('blogDescription')}
          onChange={(value) => form.setFieldsValue({ blogDescription: value })}
        />
      </Form.Item>

      <Form.Item label="Blog Author" name="blogAuthor" rules={[{ required: true, message: 'Blog Author is required' }]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Blog Date"
        name="blogDate"
        rules={[{ required: true, message: 'Blog Date is required' }]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        label="Upload Image"
        name="image"
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <Upload
          beforeUpload={() => false}
          listType="picture"
          onChange={onFileChange}
        >
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Update Blog
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditBlog;
