import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const CheckOutForm = () => {
    const { sessionId } = useParams();
    const navigate = useNavigate();

  const [formData, setFormData] = useState({
    address: "",
    phone_number: "",
    full_name: "",
   
  });

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://api.helpinghandlk.com/api/user/get-session-by-id/${sessionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          // Update the form data with the fetched session data
          setFormData(response.data.data);
        } else {
          console.error("Failed to fetch session data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching session data:", error);
      }
    };

    fetchSessionData();
  }, [sessionId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `https://api.helpinghandlk.com/api/user/SessionSlot-Perchase/${sessionId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        navigate(`/invoice/${sessionId}`);
        console.log("sucessfully send,",response)
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="container">
      <div className="pb-4">
        <h5>Price:{formData.Price}</h5>
        <h5>Date:{formData.Session_date}</h5>
        <h5>Your Number :{formData.session_number}</h5>
        <h5>hospital :{formData.location}</h5>
        <h5>Doctor:{formData.DoctorName}</h5>
      </div>
        
    <form onSubmit={handleSubmit} >
        
      <label>
        Address:
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleInputChange}
          required
        />
      </label>

      <label>
        Phone Number:
        <input
          type="text"
          name="phone_number"
          value={formData.phone_number}
          onChange={handleInputChange}
          required
        />
      </label>

      <label>
        Full Name:
        <input
          type="text"
          name="full_name"
          value={formData.full_name}
          onChange={handleInputChange}
          required
        />
      </label>

      <button type="submit">Book</button>
    </form>
    </div>
  );
};

export default CheckOutForm;
