

import React, { useEffect, useState } from "react";
import { Table, Space, Button, Modal, Spin } from "antd"; // Import Modal and Spin from antd
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons"; // Import icons
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import "./AllDoctor.css";

const AllDoctor = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false); // State for modal visibility
  const [selectedImage, setSelectedImage] = useState(null); // State to hold the selected image data

  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false); // State for delete operation loading




  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const token = localStorage.getItem("token");

        const response = await axios.get(
          "https://api.helpinghandlk.com/api/admin/get-all-doctors",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(response)

        if (response.data.success) {
          setDoctors(response.data.data);
          console.log("image",response.data.data)
        } else {
          console.log("Error fetching doctors:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching doctors:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDoctors();
  }, []);

  const handleEdit = (doctorId) => {
    navigate(`/editdoctor/${doctorId}`);
    console.log("Edit doctor with ID:", doctorId);
  };

  const handleDelete = async (doctorId) => {
    try {
      setDeleting(true); // Set deleting to true when starting the delete operation
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `https://api.helpinghandlk.com/api/admin/delete-doctor/${doctorId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        // Update the state to reflect the deletion

        setDoctors((prevDoctors) =>
          prevDoctors.filter((doctor) => doctor.id !== doctorId)
        );
        // Show success message using SweetAlert
        Swal.fire({
          icon: "success",
          title: "Doctor Deleted",
          text: response.data.message,
        });
      } else {
        console.log("Error deleting doctor:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting doctor:", error);
    } finally {
      setDeleting(false); // Set deleting back to false after deletion completes or errors
    }
  };

  const handleViewImage = (imageData) => {

    const imageDataArray = imageData.data.data;
    
  
    const uint8Array = new Uint8Array(imageDataArray);
    
   
    const blob = new Blob([uint8Array], { type: imageData.contentType });
    

    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
  
      setSelectedImage(reader.result);
      setVisible(true);
    };
  };
  
  const handleCloseModal = () => {
    setSelectedImage(null); // Reset selected image data
    setVisible(false); // Close modal
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Specialization",
      dataIndex: "spesification",
      key: "specialization",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {/* Replace buttons with icons */}
          <EditOutlined
            onClick={() => handleEdit(record.id)}
            className="icon"
          />
          <DeleteOutlined
            onClick={() => handleDelete(record.id)}
            className="icon"
          />
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={() => handleViewImage((record.image))}
            className="icon"
          />{" "}
          {/* Add View Icon */}
        </Space>
      ),
    },
  ];

  return (
    <div className="container">
      {/* Use Spin component to show loading indicator during delete operation */}
      <Spin spinning={deleting}>
        <Table
          dataSource={doctors}
          columns={columns}
          rowKey="id"
          loading={loading}
          pagination={{ pageSize: 10 }}
        />
      </Spin>
      <Modal
        title="View Doctor Photo"
        visible={visible}
        onCancel={handleCloseModal}
        footer={null}
      >
        {selectedImage && (
         
          <img
            // src={`data:${selectedImage.contentType};base64,${selectedImage.data}`}
            src={`${selectedImage}`}            
            alt="Doctor"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        )}
         
      </Modal>
    </div>
  );
 
};

export default AllDoctor;
