import React from 'react';
import './Counceling.css';

const Counseling = () => {
  return (
    <div className="residential-treatment-container">
      <div className="main-title">Counseling Service</div>
      <div className="paragraph">
        At The Helping Hand, our Counseling Facility provides a compassionate and supportive environment where individuals can engage in transformative therapeutic journeys. Our team of experienced and licensed therapists is dedicated to helping you navigate life's challenges and achieve your mental health goals.
      </div>

      <div className="paragraph">
        We offer a comprehensive range of counseling services tailored to your unique needs, including:
      </div>

      <section>
        <div className="sub-title">Individual Therapy</div>
        <div className="paragraph">
          Confidential and personalized sessions address a wide range of issues, such as anxiety, depression, trauma, and relationship difficulties.
        </div>
      </section>

      <section>
        <div className="sub-title">Group Therapy</div>
        <div className="paragraph">
          Supportive and empowering groups where individuals can share experiences, learn coping mechanisms, and develop a sense of community.
        </div>
      </section>

      <section>
        <div className="sub-title">Couples Counseling</div>
        <div className="paragraph">
          Relationship-focused therapy aims to improve communication, resolve conflicts, and strengthen bonds.
        </div>
      </section>

      <section>
        <div className="sub-title">Family Therapy</div>
        <div className="paragraph">
          Family-oriented sessions to address systemic issues, foster healthy relationships, and promote open communication.
        </div>
      </section>

      <div className="paragraph">
        Our counseling facility provides a safe and confidential space where you can explore your thoughts, feelings, and experiences without judgment. We believe in a holistic approach to mental health, recognizing the interconnectedness of mind, body, and spirit.
      </div>

      <div className="paragraph">
        Together, we will work to:
      </div>
      <div className="list-item">Enhance your emotional well-being</div>
      <div className="list-item">Develop coping skills to manage stress and adversity</div>
      <div className="list-item">Promote personal growth and self-discovery</div>
      <div className="list-item">Foster healthy relationships and support systems</div>

      <div className="paragraph">
        We are committed to providing you with the highest quality of care and helping you achieve your mental health goals. Contact us today to schedule a consultation and embark on your journey towards healing and empowerment.
      </div>
    </div>
  );
};

export default Counseling;
