// import React from "react";
// import "./AboutUs.css";

// function AboutUs() {
//   return (
//     <div className="AboutUs" id="aboutPage_sec">
//       <div>
//         <div class="custom-card">
//           <div class="title">
//             <h2>Thaaru Paranavithana</h2>
//           </div>
//           <div class="img-and-name">
//             <div class="image-place">
//               <img
//                 alt="Imagem de Carina Ponte, responsável pela área de Heranças em MSF Brasil"
//                 class="person-image"
//                 src="director.jpg"
//                 title="Imagem de Carina Ponte"
//               />
//             </div>
//             <div class="information">
//               <div class="person-name">
//                 <p id="postID">Director & Chief Psychologist</p>
//               </div>
//               <div class="job-description">
//                 BA Psycho (Spc) Kelaniya <br></br>MA ( B,Psycho) Kelaniya
//                 <br></br> MA (Sociology) Colombo<br></br> MSc (Applied &
//                 Clinical Psycho) NIBM <br></br>PGDC at university of Colombo,
//                 Psychotherapy High National Diploma (MHF)<br></br> Member of
//                 Amarican psychological association <br></br>Former psychologist
//                 at Gampha base hospital & Kiribathgoda hospital<br></br>{" "}
//                 Visiting lecturer- National police academy & Government Teacher
//                 training college bolawalana<br></br> World Vision Lanka NGO{" "}
//                 <br></br>Director- The Helping Hand psychological hospital
//               </div>

//               {/* <div class="contacts">
//                     <div class="phone-number">
//                         <p>
//                         <a href="tel:+552135273614">(21) 3527-3614</a>
//                         </p>
//                     </div>
//                     <div class="email">
//                         <p>
//                         <a href="mailto:heranca@rio.msf.org">heranca@rio.msf.org</a>
//                         </p>
//                     </div>
//                     </div> */}
//             </div>
//           </div>
//         </div>
//         <div class="custom-card">
//           <div class="title">
//             <h2>Saman Athula Kumara</h2>
//           </div>
//           <div class="img-and-name">
//             <div class="image-place">
//               <img
//                 alt="Imagem de Carina Ponte, responsável pela área de Heranças em MSF Brasil"
//                 class="person-image"
//                 src="6596121.png"
//                 title="Imagem de Carina Ponte"
//               />
//             </div>
//             <div class="information">
//               <div class="person-name">
//                 <p id="postID">Manager</p>
//               </div>
//               <div class="job-description">
//                 SRI LANKA GOVERNMENT PRICIPLE SERVICE<br></br> Mahaweli College
//                 Of Education<br></br> The National Diploma in Teaching <br></br>
//                 Former principal with more than 20 year's experiences<br></br>{" "}
//                 Level II Capacity Development Program (Enhancement of Education
//                 Achievement)<br></br> Department of Education, Western Province
//                 Level I Capacity Development Program (Smart School Leaders).
//                 Department of Education, Western Province
//               </div>

//               {/* <div class="contacts">
//                     <div class="phone-number">
//                         <p>
//                         <a href="tel:+552135273614">(21) 3527-3614</a>
//                         </p>
//                     </div>
//                     <div class="email">
//                         <p>
//                         <a href="mailto:heranca@rio.msf.org">heranca@rio.msf.org</a>
//                         </p>
//                     </div>
//                     </div> */}
//             </div>
//           </div>
//         </div>
//         <div class="custom-card">
//           <div class="title">
//             <h2>Dr. B.M. Dayan Samarasekara</h2>
//           </div>
//           <div class="img-and-name">
//             <div class="image-place">
//               <img
//                 alt="Imagem de Carina Ponte, responsável pela área de Heranças em MSF Brasil"
//                 class="person-image"
//                 src="6596121.png"
//                 title="Imagem de Carina Ponte"
//               />
//             </div>
//             <div class="information">
//               <div class="person-name">
//                 <p id="postID">MEDICAL OFFICER</p>
//               </div>
//               <div class="job-description">
//                 MBBS (University of Sri Jayawardana Pura) <br></br>Post Graduate
//                 Diploma in Elderly Medicine, University of Colombo<br></br>{" "}
//                 Diploma in Counseling Psychology, University of Colombo<br></br>{" "}
//                 MRCGP (Int) (Part 1)<br></br> Orientation for Medical Officers
//                 of Health, National Institute of Health Sciences, 2009<br></br>
//                 Preventive sector and primary care <br></br>Worked as Medical
//                 officer of Health Kantale <br></br> Additional Medical officer
//                 of Health Divulapitiya, Seeduwa, Minuwangoda<br></br> Curative
//                 sector and Tertiary care <br></br>Senior House Officer
//                 (Medicine) (DGH Negombo)<br></br> Medical Officer , DGH Gampaha
//               </div>

//               {/* <div class="contacts">
//                     <div class="phone-number">
//                         <p>
//                         <a href="tel:+552135273614">(21) 3527-3614</a>
//                         </p>
//                     </div>
//                     <div class="email">
//                         <p>
//                         <a href="mailto:heranca@rio.msf.org">heranca@rio.msf.org</a>
//                         </p>
//                     </div>
//                     </div> */}
//             </div>
//           </div>
//         </div>

//         <div class="custom-card">
//           <div class="title">
//             <h2>K.R.M.Y. Harshani Kasthurirathna</h2>
//           </div>
//           <div class="img-and-name">
//             <div class="image-place">
//               <img
//                 alt="Imagem de Carina Ponte, responsável pela área de Heranças em MSF Brasil"
//                 class="person-image"
//                 src="6596121.png"
//                 title="Imagem de Carina Ponte"
//               />
//             </div>
//             <div class="information">
//               <div class="person-name">
//                 <p id="postID">Professional Psycho Therapist</p>
//               </div>
//               <div class="job-description">
//                 Studied Bachelor of Art (special) degree at university of Sri
//                 Jayawardharapura. <br></br>Studied Advanced Diploma In
//                 Psychotherapy at Mental Health Foundation.<br></br> Studied
//                 Master of Art in Buddhist Ayurveda Counselling at University of
//                 Kelaniya. <br></br>Studied Diploma Course in counseling at
//                 University of Sri Jayawadhanapura (Department of Psychiatry).{" "}
//                 <br></br>Postgraduate Diploma at University of Colombo.
//                 Registered Counselor and member of Professional Psychological
//                 Counselors Association in Sri Lanka. ( Sri Lanka foundation
//                 Institute)
//               </div>

//               {/* <div class="contacts">
//                     <div class="phone-number">
//                         <p>
//                         <a href="tel:+552135273614">(21) 3527-3614</a>
//                         </p>
//                     </div>
//                     <div class="email">
//                         <p>
//                         <a href="mailto:heranca@rio.msf.org">heranca@rio.msf.org</a>
//                         </p>
//                     </div>
//                     </div> */}
//             </div>
//           </div>
//         </div>
//         <div class="custom-card">
//           <div class="title">
//             <h2> Chitharani Priyangani De Silva</h2>
//           </div>
//           <div class="img-and-name">
//             <div class="image-place">
//               <img
//                 alt="Imagem de Carina Ponte, responsável pela área de Heranças em MSF Brasil"
//                 class="person-image"
//                 src="6596121.png"
//                 title="Imagem de Carina Ponte"
//               />
//             </div>
//             <div class="information">
//               <div class="person-name">
//                 <p id="postID">Educational Counsellor</p>
//               </div>
//               <div class="job-description">
//                 Bachelor of Arts Degree at the University of Kelaniya, Sri
//                 Lanka. <br></br>Postgraduate Diploma in Education Management at
//                 Faculty of Education Leadership Development and Management
//                 Meepe, Sri Lanka. <br></br>The certificate course in educational
//                 research methodology, University of Colombo, Sri Lanka.{" "}
//                 <br></br>Postgraduate Diploma in Education at University of
//                 Colombo, Sri Lanka Trained Teacher Certificate (primary
//                 education) at National Institute of Education, Sri Lanka.
//                 <br></br> Care Certificate at FLEXEBEE.LTD, United Kingdom.
//                 <br></br> OTHM Level 03 Foundation Diploma in Health and Social
//                 Care Northern Campus, United Kingdom Completed a Care Giver
//                 Course at Feel It Nursing Academy, Minuwangoda, Sri Lanka.{" "}
//                 <br></br>Master of Education (Education and Development
//                 Psychology) at the University of Colombo, Sri Lanka.
//               </div>

//               {/* <div class="contacts">
//                     <div class="phone-number">
//                         <p>
//                         <a href="tel:+552135273614">(21) 3527-3614</a>
//                         </p>
//                     </div>
//                     <div class="email">
//                         <p>
//                         <a href="mailto:heranca@rio.msf.org">heranca@rio.msf.org</a>
//                         </p>
//                     </div>
//                     </div> */}
//             </div>
//           </div>
//         </div>
//         <div class="custom-card">
//           <div class="title">
//             <h2> W.A.Shanika Madubhashini</h2>
//           </div>
//           <div class="img-and-name">
//             <div class="image-place">
//               <img
//                 alt="Imagem de Carina Ponte, responsável pela área de Heranças em MSF Brasil"
//                 class="person-image"
//                 src="6596121.png"
//                 title="Imagem de Carina Ponte"
//               />
//             </div>
//             <div class="information">
//               <div class="person-name">
//                 <p id="postID">Professional Psycho Therapist</p>
//               </div>
//               <div class="job-description">
//                 Bachelor of Arts (Special) Degree in Psychology. <br></br>
//                 Followed a Diploma (Part I & II) in Counselling and
//                 Psychotherapy in the Institute of Psychological Studies,
//                 Colombo, Sri Lanka.<br></br> Followed a Diploma in Applied
//                 Psychology and Counselling One Year Course with Clinical
//                 Practical conducted by the Life Center, Sri Lanka.<br></br>{" "}
//                 Followed a Course in Practical Psychiatry and Clinical
//                 Psychology in the Life Center for Personality Development,
//                 Kohuwala, Sri Lanka.<br></br> Clinical Training Programme
//                 Conducted by Sandeepani Home, Psychosocial Residential Care for
//                 Mentally Ill.<br></br>Professional Psychologist at National
//                 Dangerous Drugs Control Board, Colombo, Sri Lanka.
//               </div>

//               {/* <div class="contacts">
//                     <div class="phone-number">
//                         <p>
//                         <a href="tel:+552135273614">(21) 3527-3614</a>
//                         </p>
//                     </div>
//                     <div class="email">
//                         <p>
//                         <a href="mailto:heranca@rio.msf.org">heranca@rio.msf.org</a>
//                         </p>
//                     </div>
//                     </div> */}
//             </div>
//           </div>
//         </div>
//         <div class="custom-card">
//           <div class="title">
//             <h2>M.G. Anuththara Indumini Mapage</h2>
//           </div>
//           <div class="img-and-name">
//             <div class="image-place">
//               <img
//                 alt="Imagem de Carina Ponte, responsável pela área de Heranças em MSF Brasil"
//                 class="person-image"
//                 src="Shadowteacher.png"
//                 title="Imagem de Carina Ponte"
//               />
//             </div>
//             <div class="information">
//               <div class="person-name">
//                 <p id="postID">Shadow Teacher</p>
//               </div>
//               <div class="job-description">
//                 Diploma in Child Psychology<br></br> Diploma in Counselling
//                 Psychology<br></br> Higher National Diploma in Special Needs
//                 Education<br></br> Internship in The Helping Hand Psychological
//                 Hospital<br></br> Reading Bachelor of Information Technology at
//                 University of Colombo School of Computing
//               </div>

//               {/* <div class="contacts">
//                     <div class="phone-number">
//                         <p>
//                         <a href="tel:+552135273614">(21) 3527-3614</a>
//                         </p>
//                     </div>
//                     <div class="email">
//                         <p>
//                         <a href="mailto:heranca@rio.msf.org">heranca@rio.msf.org</a>
//                         </p>
//                     </div>
//                     </div> */}
//             </div>
//           </div>
//         </div>
//         <div class="custom-card">
//           <div class="title">
//             <h2>Ruhaniya Roomy</h2>
//           </div>
//           <div class="img-and-name">
//             <div class="image-place">
//               <img
//                 alt="Imagem de Carina Ponte, responsável pela área de Heranças em MSF Brasil"
//                 class="person-image"
//                 src="eldercare.png"
//                 title="Imagem de Carina Ponte"
//               />
//             </div>
//             <div class="information">
//               <div class="person-name">
//                 <p id="postID">Elder Care Assistant</p>
//               </div>
//               <div class="job-description">
//                 Diploma in Counselling Psychology<br></br> Internship in The
//                 Helping Hand Psychological Hospital<br></br> As a teacher in
//                 Kids International School in Sri Lanka for 3 years. <br></br>
//                 Completed on Information Technology course in Esoft Metro Campus
//                 Seeduwa
//               </div>

//               {/* <div class="contacts">
//                     <div class="phone-number">
//                         <p>
//                         <a href="tel:+552135273614">(21) 3527-3614</a>
//                         </p>
//                     </div>
//                     <div class="email">
//                         <p>
//                         <a href="mailto:heranca@rio.msf.org">heranca@rio.msf.org</a>
//                         </p>
//                     </div>
//                     </div> */}
//             </div>
//           </div>
//         </div>
//         <div class="custom-card">
//           <div class="title">
//             <h2>Amali Ureshani Silva</h2>
//           </div>
//           <div class="img-and-name">
//             <div class="image-place">
//               <img
//                 alt="Imagem de Carina Ponte, responsável pela área de Heranças em MSF Brasil"
//                 class="person-image"
//                 src="specialneeds.png"
//                 title="Imagem de Carina Ponte"
//               />
//             </div>
//             <div class="information">
//               <div class="person-name">
//                 <p id="postID">Special Needs Education Teacher</p>
//               </div>
//               <div class="job-description">
//                 Diploma in Child Psychology (SLIT Campus) <br></br>Social Work
//                 Diploma (University of Japura)<br></br> Higher National Diploma
//                 in Special Needs Education (The Helping Hand Hospital)
//               </div>

//               {/* <div class="contacts">
//                     <div class="phone-number">
//                         <p>
//                         <a href="tel:+552135273614">(21) 3527-3614</a>
//                         </p>
//                     </div>
//                     <div class="email">
//                         <p>
//                         <a href="mailto:heranca@rio.msf.org">heranca@rio.msf.org</a>
//                         </p>
//                     </div>
//                     </div> */}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default AboutUs;




import React from 'react';
import './AboutUs.css';
// Adjust the path accordingly

const teamMembers = [
  {
      imgUrl: '/director.jpg',
      name: 'Thaaru Paranavithana',
      position: 'Director & Chief Psychologist'
  },

  {
      imgUrl: '/anuththara.jpg',
      name: 'M.G.A Indumini Mapage',
      position: 'Shadow Teacher'
  },

  {
      imgUrl: '/eldercare.png',
      name: 'Ruhaniya Roomy',
      position: 'Elder Care Assistant'
  },

  {
      imgUrl: '/specialneeds.png',
      name: 'Amali Ureshani Silva',
      position: 'Special Needs Education Teacher'
  },

  
]

const teamMembers2 = [
  {
      imgUrl: '/athula.JPG',
      name: 'Saman Athula Kumara',
      position: 'Manager'
  },

  {
      imgUrl: '/dayan.jpg',
      name: 'Dr. B.M. Dayan Samarasekara',
      position: 'MEDICAL OFFICER'
  },

  {
      imgUrl: '/harshani.jpg',
      name: 'K.R.M.Y. Harshani Kasthurirathna',
      position: 'Professional Psycho Therapist'
  },

  {
      imgUrl: '/chithrani.JPG',
      name: 'Chitharani Priyangani De Silva',
      position: 'Educational Counsellor'
  },

  
]

const teamMembers3 = [
  {
      imgUrl: '/shanika.JPG',
      name: 'W.A.Shanika Madubhashini',
      position: 'Professional Psycho Therapist'
  },

  {
    imgUrl: '/nurse.jpeg',
    name: 'S. Ashani Ranganee',
    position: 'Nursing Officer'
},

{
    imgUrl: '/medical.jpeg',
    name: 'Dr. Vishwanee Hasanthika Samarasekara ',
    position: 'Medical Officer'
},

{
    imgUrl: '/physio.jpeg',
    name: 'Ajith Thannakoon',
    position: 'Physiotherapy'
},

]

const teamMembers4 = [
    {
        imgUrl: '/assis.jpeg',
        name: 'Sandeepa Fernando',
        position: 'Assistant counselor '
    },
  
    {
      imgUrl: '/addiction.jpeg',
      name: 'Dilhani Karunathilaka',
      position: 'Addiction Counselor '
  },
  
  {
      imgUrl: '/ayrudu.jpeg',
      name: 'Dr. Sanatha Wijesooriya  ',
      position: 'Ayururwedic physician'
  },
  

  ]

const AboutUs = () => {
    return (
        <section className='our__team'>
            <div className='container'>
                <div className='team__content'>
                    <h6 className='subtitle'>Our Team</h6>
                    <h2>
                        Join With <span className='highlight'>Our Team</span>
                    </h2>
                </div>
                <div className='team__wrapper'>
                    {teamMembers.map((item, index) => (
                        <div className='team__item' key={index}>
                            <div className='team__img'>
                                <img src={process.env.PUBLIC_URL + item.imgUrl} alt='' />
                            </div>
                            <div className='team__details'>
                                <h4>{item.name}</h4>
                                <p className='description'>{item.position}</p>

                                
                            </div>
                        </div>
                    ))}
                </div>

                <div className='team__wrapper'>
                    {teamMembers2.map((item, index) => (
                        <div className='team__item' key={index}>
                            <div className='team__img'>
                                <img src={process.env.PUBLIC_URL + item.imgUrl} alt='' />
                            </div>
                            <div className='team__details'>
                                <h4>{item.name}</h4>
                                <p className='description'>{item.position}</p>

                                
                            </div>
                        </div>
                    ))}
                </div>
                <div className='team__wrapper'>
                    {teamMembers3.map((item, index) => (
                        <div className='team__item' key={index}>
                            <div className='team__img'>
                                <img src={process.env.PUBLIC_URL + item.imgUrl} alt='' />
                            </div>
                            <div className='team__details'>
                                <h4>{item.name}</h4>
                                <p className='description'>{item.position}</p>

                                
                            </div>
                        </div>
                    ))}
                </div>

                <div className='team__wrapper'>
                    {teamMembers4.map((item, index) => (
                        <div className='team__item' key={index}>
                            <div className='team__img'>
                                <img src={process.env.PUBLIC_URL + item.imgUrl} alt='' />
                            </div>
                            <div className='team__details'>
                                <h4>{item.name}</h4>
                                <p className='description'>{item.position}</p>

                                
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </section>
    );
};

export default AboutUs;
