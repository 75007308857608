import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Home.css";
import { Link, useNavigate } from "react-router-dom";
import { FaWhatsapp } from 'react-icons/fa'; // Import WhatsApp icon from react-icons
import n1 from '../Assets/n1.JPG';
import n2 from '../Assets/n2.JPG';
import n3 from '../Assets/n3.JPG';

const HomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);
  
  const handleRedirect = () => {
    navigate('/Residential');
  };

  const handlePsychiatric = () => {
    navigate('/Psychiatric');
  };

  const handleCounceling = () => {
    navigate('/Counceling');
  };

  const handleBehavioral = () => {
    navigate('/Behavioral');
  };

  const handleTraining = () => {
    navigate('/Training');
  };

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/719001212', '_blank'); // Updated with your WhatsApp number
  };

  return (
    <div className="home-page">
      <header className="banner">
        <img src="/banner.jpg" alt="Banner" />
        <div className="banner-content">
          <div data-aos="fade-down" data-aos-duration="1000">
            <h1>
              WELCOME TO
              <br />
              HELPING HAND
            </h1>
            <br />
            <h2 className="site-heading-title">PSYCHOLOGICAL HOSPITAL</h2>
            <br />
            <a href="#footer_sec" className="contact-button">
              Contact Us
            </a>
          </div>
        </div>
      </header>

      <main>
        <div id="service_section">
          <section className="we-offer-areaa text-center bg-gray" id="service_section">
            <div className="containers">
              <div className="row">
                <div className="col-md-12">
                  <div className="site-heading text-center">
                    <div data-aos="fade-up" data-aos-duration="1500">
                      <h2 id="medicalServicesTopic">
                        Choose Our <span>Medical Services</span>
                      </h2>
                      <h4>
                        provide a wide range of mental health services to people of all ages and backgrounds
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row our-offer-items less-carousel">
                <div className="col-md-4 col-sm-6 equal-height" onClick={handleRedirect}>
                  <div data-aos="fade-right" data-aos-duration="1500">
                    <div className="item">
                      <div className="service-icon">
                        <img src="residentialTreatement.png" alt="Therapy" />
                      </div>
                      <br />
                      <h5 id="serivceHeadings">Residential Treatment</h5>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-6 equal-height" onClick={handlePsychiatric}>
                  <div data-aos="fade-up" data-aos-duration="1500">
                    <div className="item">
                      <div className="service-icon">
                        <img src="PsychiatricTreatment.jpeg" alt="Counseling" />
                      </div>
                      <br />
                      <h5 id="serivceHeadings">Psychiatric Treatment</h5>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-6 equal-height" onClick={handleCounceling}>
                  <div data-aos="fade-left" data-aos-duration="1500">
                    <div className="item">
                      <div className="service-icon">
                        <img src="counselling.avif" alt="Medication Management" />
                      </div>
                      <br />
                      <h5 id="serivceHeadings">Counseling</h5>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-6 equal-height" onClick={handleBehavioral}>
                  <div data-aos="fade-right" data-aos-duration="1500">
                    <div className="item">
                      <div className="service-icon">
                        <img src="BehavioralTherapy.jpeg" alt="Behavioral Therapy" />
                      </div>
                      <br />
                      <h5 id="serivceHeadings">Behavioral Therapy</h5>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-6 equal-height" onClick={handleTraining}>
                  <div data-aos="fade-up" data-aos-duration="1500">
                    <div className="item">
                      <div className="service-icon">
                        <img src="training.jpeg" alt="Training" />
                      </div>
                      <br />
                      <h5 id="serivceHeadings">Training for Psychology Professionals</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div id="about_secs">
          <section className="about-sections">
            <div className="container">
              <div className="row">
                <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                  <div className="inner-column">
                    <div className="sec-titlea">
                      <h3 className="abouttitle">
                        Empowering Minds, Embracing Change: Your Journey to Inner Peace
                      </h3>
                    </div>
                    <div className="text">
                      Our hospital is a distinguished private healthcare institution committed to advancing mental health and psychological well-being. With a focus on providing premium care and support, this hospital offers a wide spectrum of services, including inpatient and outpatient rehabilitation, counseling, and psychotherapy. The dedicated team of professionals at The Helping Hand Psychological Hospital delivers personalized treatment and therapies, ensuring that each patient's unique needs are met. With a strong emphasis on confidentiality and comfort, the hospital strives to create a nurturing environment for individuals seeking expert mental health care in a private and compassionate setting.
                    </div>
                    <br />
                    <div>
                      <Link to="/aboutus">
                        <button className="theme-btn btn-style-one">
                          LEARN MORE
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="image-column col-lg-6 col-md-12 col-sm-12">
                  <div data-aos="fade-right" data-aos-duration="1500">
                    <div className="inner-column wow">
                      <div className="author-desc">
                        <h2 className="ownerName">Thaaru Paranavithana</h2>
                        <span>Director & Chief Psychologist</span>
                      </div>
                      <figure className="image-1">
                        <a href="#" className="lightbox-image" data-fancybox="images">
                          <img
                            title="Rahul Kumar Yadav"
                            src="director.jpg"
                            alt=""
                            style={{ width: '100%', height: 'auto', maxWidth: '400px' }} // Adjust the maximum width as needed
                          />
                        </a>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div id="news_section">
  <section className="news-section text-center">
    <div className="container">
      <div className="site-heading text-center">
        <div data-aos="fade-up" data-aos-duration="1500">
          <h2>Latest <span>News</span></h2>
          <h4>Stay updated with our latest news and events</h4>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8 col-sm-12">
          <div data-aos="fade-right" data-aos-duration="1500">
            <div className="news-item">
              <img src={n1} alt="News 1" />
              <h5>Helping Hand Psychological Hospital, and Sri Lanka Institute of Information Technology Limited (SLIIT), come together to build a long-term partnership between the university and the industry.
              </h5>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-12">
          <div data-aos="fade-up" data-aos-duration="1500">
            <div className="news-item">
              <img src={n2} alt="News 2" />
              <h5>Grateful for the opportunity to conduct psychology sessions at the Maldives National Drug Agency and work towards developing their staff.
              </h5>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-12">
          <div data-aos="fade-left" data-aos-duration="1500">
            <div className="news-item">
              <img src={n3} alt="News 3" />
              <h5>Helping Hand Welcomes Future Psychologists: Jaffna and Eastern Students Train for Success!</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>



        <section className="partners-section" id="partners-section">
          <div className="container">
            <h2 data-aos="fade-up" data-aos-duration="1000">
              Collaborating for Success.
            </h2>
            <br />
            <div className="row">
              <div className="col-md-6">
                <a
                  href="https://www.sliit.lk/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className="partner-item"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <img src="https://firebasestorage.googleapis.com/v0/b/helphands-7363c.appspot.com/o/SLIIT_Logo_Crest.png?alt=media&token=5f1edb69-ce30-4c46-ad2e-cf7d95d2f6b8" alt="University 1" />
                    <h4>Sri Lanka Institute Of Information Technology</h4>
                  </div>
                </a>
              </div>
              <div
                className="col-md-6"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="200"
              >
                <div className="partner-item">
                  <a href="https://www.facebook.com/share/zitsS9bgPQx8uQKT/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                    <img src="/partners.png" alt="University 2" />
                  </a>
                  <h4>Moti Virus</h4>
                </div>
              </div>
            </div>
          </div>
        </section>


    <div className="whatsapp-float" onClick={handleWhatsAppClick}>
      <FaWhatsapp className="whatsapp-icon" />
      <span className="whatsapp-text">Chat with us now</span>
    </div>
  

      </main>
    </div>
  );
};

export default HomePage;
