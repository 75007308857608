import React from 'react';
import './Certificate.css';

const Certificate = () => {
  return (
    <div className="certificate-container">
      <h1>Our Certificate Courses</h1>
      
      <div className="course-info">
        <div>
          <p><strong>Course Duration:</strong> 4 months</p>
        </div>
        <div>
          <p><strong>Course Fee:</strong></p>
          <ul>
            <li>Sri Lanka Rupee (LKR): 35,000</li>
            <li>US Dollar (USD): 150 (for foreign students)</li>
          </ul>
        </div>
      </div>

      <div className="course-offerings">
        <h2>Course Offerings</h2>
        <p>The Helping Hand Psychological Hospital offers four Certificate Courses in Psychology:</p>
        <ul>
          <li>Personality Development Therapy</li>
          <li>Psychoanalysis Therapy</li>
          <li>CBT Treatment</li>
          <li>Goal Setting Techniques</li>
        </ul>
      </div>

      <div className="syllabus">
        <h2>Syllabus</h2>
        
        <div className="course">
          <h3>Personality Development Therapy</h3>
          <ul>
            <li>Understanding personality development</li>
            <li>Theories of personality</li>
            <li>Factors influencing personality</li>
            <li>Techniques for personality development</li>
            <li>Applications of personality development therapy</li>
          </ul>
        </div>
        
        <div className="course">
          <h3>Psychoanalysis Therapy</h3>
          <ul>
            <li>History and principles of psychoanalysis</li>
            <li>Psychoanalytic theory and practice</li>
            <li>Unconscious processes and defense mechanisms</li>
            <li>Transference and countertransference</li>
            <li>Applications of psychoanalysis in therapy</li>
          </ul>
        </div>

        <div className="course">
          <h3>CBT Treatment</h3>
          <ul>
            <li>Principles of Cognitive Behavioral Therapy (CBT)</li>
            <li>Cognitive and behavioral models of disorders</li>
            <li>CBT techniques for managing thoughts, emotions, and behaviors</li>
            <li>Applications of CBT in treating various mental health conditions</li>
          </ul>
        </div>

        <div className="course">
          <h3>Goal Setting Techniques</h3>
          <ul>
            <li>Importance and benefits of goal setting</li>
            <li>Types of goals and goal setting models</li>
            <li>SMART goals and achievement strategies</li>
            <li>Overcoming obstacles and maintaining motivation</li>
            <li>Applications of goal setting in personal and professional life</li>
          </ul>
        </div>
      </div>

      <div className="details">
        <h2>Details</h2>
        <ul>
          <li>The courses are taught by experienced psychologists and therapists.</li>
          <li>Classes are held at the Helping Hand Psychological Hospital in Negombo, Sri Lanka.</li>
          <li>The courses are open to individuals from all backgrounds with an interest in psychology.</li>
          <li>No prior knowledge of psychology is required.</li>
          <li>Upon completion of the course, participants will receive a certificate from the Helping Hand Psychological Hospital.</li>
          <li>The hospital provides a supportive and learning environment for its students.</li>
        </ul>
      </div>
    </div>
  );
};

export default Certificate;
